import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isNone } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { Breadcrumb } from 'mobile-web/components/breadcrumbs';
import { MAX_RECIPIENT_NAME_LENGTH } from 'mobile-web/lib/customer';
import { createDefaultBasketChoices } from 'mobile-web/lib/menu';
import { PreviousRoute } from 'mobile-web/lib/routing';
import { safeLater, safeNext } from 'mobile-web/lib/runloop';
import isSome from 'mobile-web/lib/utilities/is-some';
import BasketProductModel from 'mobile-web/models/basket-product';
import ProductModel from 'mobile-web/models/product';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import { ProductClickFrom } from 'mobile-web/services/global-data';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import GroupOrderService from 'mobile-web/services/group-order';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

interface Args {
  // Required arguments
  product: ProductModel;
  displayInModal: boolean;

  // Optional arguments
  previousRoute?: PreviousRoute;
  basketProduct?: BasketProductModel;
  onSave?: Action;
  isLoading?: boolean;
  clickFrom?: ProductClickFrom;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export enum ProductCustomizationType {
  Modal = 'modal',
  Page = 'page',
}

export default class ProductCustomization extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service error!: ErrorService;
  @service globalEvents!: GlobalEventsService;
  @service groupOrder!: GroupOrderService;
  @service store!: DS.Store;
  @service vendor!: VendorService;
  @service features!: FeaturesService;

  // Untracked properties
  style = style;
  maxRecipientNameLength = MAX_RECIPIENT_NAME_LENGTH;

  // Tracked properties
  @tracked currentBasketProduct?: BasketProductModel;
  @tracked footerElement?: HTMLElement;
  @tracked hasInitialized = false;

  // Need to track the previous activeBasketProduct for the use case of
  // opening a product edit modal on top of an open product customization page
  previousActiveBasketProduct?: BasketProductModel;

  // Getters and setters
  get rootStyle() {
    if (this.args.displayInModal) {
      const footerHeight = this.footerElement?.offsetHeight ?? 0;
      const marginBottom = footerHeight + 16; // $spacing-lg
      return htmlSafe(`margin-bottom: ${marginBottom}px`);
    }
    return undefined;
  }

  get showBreadcrumbs(): boolean {
    return this.device.isWeb && !this.args.displayInModal;
  }

  get breadcrumbs(): Breadcrumb[] {
    const product = this.args.product;
    const vendor = product.vendor ?? this.vendor.vendor;
    const breadcrumbs: Breadcrumb[] = [
      { label: `${vendor.name} Menu`, route: 'menu.vendor', models: [vendor.slug] },
    ];
    if (this.args.previousRoute?.route === 'menu.category' && product.category) {
      const category = product.category;
      breadcrumbs.push({
        label: category.name,
        route: 'menu.category',
        models: [vendor.slug, category.id],
      });
    }
    return [...breadcrumbs, { label: product.name }];
  }

  get basketProduct(): BasketProductModel | undefined {
    const bp = this.args.basketProduct ?? this.currentBasketProduct;
    if (!bp) {
      this.error.sendExternalError(new Error('basketProduct was undefined'), {
        productId: this.args.product?.id,
      });
    }
    return bp;
  }

  get showOptionGroups(): boolean {
    return isSome(this.basket.activeBasketProduct) && this.hasInitialized && !this.args.isLoading;
  }

  get showRecipientNameInput(): boolean {
    return !!this.channel.settings?.showProductRecipientNameLabel && !this.groupOrder.hasGroupOrder;
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.basketProduct) {
      this.hasInitialized = true;
      // This may seem like we're duplicating work done in the edit route model hook,
      // but actually we need this for when we open the product modal to edit a simple product.
      this.previousActiveBasketProduct = this.basket.safeGetActiveBasketProduct();
      this.basket.activeBasketProduct = this.args.basketProduct;
      this.currentBasketProduct = this.args.basketProduct;
    } else {
      const product = this.args.product;

      const bp = this.store.createRecord('basket-product', {
        quantity: product.defaultQuantity,
        product,
        vendor: product.vendor,
      });
      this.currentBasketProduct = bp;

      createDefaultBasketChoices(this.store, bp, product.optionGroups).then(() => {
        product.optionGroups.forEach(og => {
          if (bp!.get('isNew')) {
            og.splitChoiceQuantities();
          }
        });
        bp!.setQuantityBasedOnChoiceQuantities();
        this.hasInitialized = true;
        safeNext(this, () => {
          // Setting footerElement to itself causes rootStyle to recalculate, which
          // is needed because hasInitialized causes the footer height to change.
          // Sorry.
          // eslint-disable-next-line no-self-assign
          this.footerElement = this.footerElement;
        });
      });

      this.previousActiveBasketProduct = this.basket.safeGetActiveBasketProduct();
      this.basket.activeBasketProduct = bp;
    }
  }

  // eslint-disable-next-line ember/require-super-in-lifecycle-hooks
  willDestroy() {
    const bp = this.args.basketProduct ?? this.currentBasketProduct;
    if (bp && !bp.isDestroying && !bp.isDestroyed) {
      bp.rollbackAttributes();
      this.store
        .peekAll('basket-choice')
        .toArray()
        .forEach(bc => {
          bc.rollbackAttributes();
          if (isNone(bc.basketProduct)) {
            // If a basket choice becomes orphaned, we need to clean it up.
            // Done in a later to not cause errors with watchers
            safeLater(bc, () => bc.unloadRecord(), 100);
          }
        });
    }
    //We need to fallback to the safeActiveProduct in case the previousActiveProduct is undefined
    this.basket.activeBasketProduct =
      this.previousActiveBasketProduct ?? this.basket.safeGetActiveBasketProduct();
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  didInsertNode() {
    this.globalEvents.trigger(
      GlobalEventName.ViewProductDetail,
      this.args.product.serializeForGlobalData(),
      this.args.displayInModal ? ProductCustomizationType.Modal : ProductCustomizationType.Page
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProductCustomization: typeof ProductCustomization;
  }
}
