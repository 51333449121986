export function addScript(
  src: string,
  onLoad?: Action,
  onError?: Action,
  attributes?: { [key: string]: string }
): void {
  const existing = document.querySelector(`script[src="${src}"]`);
  if (existing) {
    onLoad?.();
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.type = 'text/javascript';
  script.src = src;
  if (onLoad) {
    script.onload = onLoad;
  }

  if (onError) {
    script.onerror = onError;
  }

  if (attributes) {
    for (const key in attributes) {
      if (attributes.hasOwnProperty(key)) {
        script.setAttribute(key, attributes[key]);
      }
    }
  }

  document.body.appendChild(script);
}

export default { addScript };
