import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { MediaService } from 'ember-responsive';

import DeviceService from 'mobile-web/services/device';
import FeaturesService from 'mobile-web/services/features';
import SessionService from 'mobile-web/services/session';

import StorageService from './storage';

export default class SideMenuService extends Service {
  // Service injections
  @service device!: DeviceService;
  @service features!: FeaturesService;
  @service media!: MediaService;
  @service session!: SessionService;
  @service storage!: StorageService;

  // Untracked properties

  // Tracked properties
  @tracked private _isActive = false;

  // Getters and setters
  get isActive(): boolean {
    return this._isActive && this.isEnabled;
  }

  get isEnabled(): boolean {
    return (
      this.storage.showSideMenu ||
      (!this.session.isEmbeddedMode &&
        !this.session.isRestrictedFlow &&
        !(this.device.isWeb && this.media.isDesktop))
    );
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  open(): void {
    if (!this._isActive) {
      this._isActive = true;
    }
  }

  @action
  close(): void {
    if (this._isActive) {
      this._isActive = false;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'side-menu': SideMenuService;
  }
}
