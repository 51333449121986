import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Breadcrumb } from 'mobile-web/components/breadcrumbs';
import isSome from 'mobile-web/lib/utilities/is-some';
import CategoryModel from 'mobile-web/models/category';
import ProductModel, { GlobalProduct } from 'mobile-web/models/product';
import ChannelService from 'mobile-web/services/channel';
import { ProductClickFrom } from 'mobile-web/services/global-data';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

interface Args {
  // Required arguments
  category: CategoryModel;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class MenuCategoryRoute extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;
  @service storage!: StorageService;
  @service globalEvents!: GlobalEventsService;
  @service vendor!: VendorService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked productCards: Element[] = [];
  @tracked previouslyVisibleProductIds: string[] = [];

  // Getters and setters
  get isLoaded(): boolean {
    return this.args.category.products.every(p => p.isLoaded);
  }

  get visibleProducts(): ProductModel[] {
    return this.args.category.isRecentItem || this.args.category.isMostOrdered
      ? this.vendor.sortAndFilterRecommendations(this.clickFrom, this.args.category.products)
      : this.args.category.products.filter(product => product.isVisible).sortBy('isDisabled');
  }

  get breadcrumbs(): Breadcrumb[] {
    const vendor = this.args.category.vendor;
    return [
      { label: `${vendor.name} Menu`, route: 'menu.vendor', models: [vendor.slug] },
      { label: this.args.category.name },
    ];
  }

  get clickFrom(): ProductClickFrom {
    return this.args.category.isRecentItem
      ? ProductClickFrom.RecentItem
      : this.args.category.isMostOrdered
      ? ProductClickFrom.MostOrdered
      : ProductClickFrom.Category;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  handleProductsVisible(visible: Element[]) {
    const [products, ids] = visible.reduce<[GlobalProduct[], string[]]>(
      (acc, el) => {
        const idx = el.getAttribute('data-idx')!;
        const product =
          isSome(idx) && this.visibleProducts.objectAt(+idx)?.serializeForGlobalData();
        if (product && !this.previouslyVisibleProductIds.includes(product.id)) {
          acc[0].push(product);
          acc[1].push(product.id);
        }
        return acc;
      },
      [[], []]
    );
    this.previouslyVisibleProductIds = this.previouslyVisibleProductIds.concat(ids);
    if (products.length) {
      this.globalEvents.trigger(GlobalEventName.ProductsVisible, products);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::Menu::CategoryRoute': typeof MenuCategoryRoute;
  }
}
