import RSVP from 'rsvp';

import { OnPremiseExperience } from 'mobile-web/lib/on-premise';

import ApplicationAdapter from './application';

export default class SessionAdapter extends ApplicationAdapter {
  clearOnPremiseData(): RSVP.Promise<{
    tablePosRef: string;
    experienceType: OnPremiseExperience;
  }> {
    const url = this.buildURL('session/clearOnPremiseData');
    return this.ajax(url, 'POST');
  }

  setOnPremiseData(
    tablePosRef: string | undefined,
    experienceType: OnPremiseExperience
  ): RSVP.Promise<{
    tablePosRef: string;
    experienceType: OnPremiseExperience;
  }> {
    const url = this.buildURL('session/setOnPremiseData');
    return this.ajax(url, 'POST', { data: { tablePosRef, experienceType } });
  }
}
