import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { getParents } from 'mobile-web/lib/dom';

interface Scrollable {
  scrollLeft: number;
  scrollTop: number;
}

/**
 * The Scroll service manages scrolling across Serve.
 */
export default class ScrollService extends Service {
  // Service injections

  // Untracked properties
  readonly categoryResizeObserver = new ResizeObserver(this.resizeCategories);

  private categoryHeights = new WeakMap<HTMLElement, number>();
  private scrollParents = new WeakMap<HTMLElement, HTMLElement>();
  private _scrollIntoViewOptions = { selector: '', alignToTop: true, waitCount: 0, retryCount: 0 };

  // Tracked properties
  /**
   * Indicates whether the browser is scrolling down the page and that dynamic
   * content should not be loaded in so that the browser can scroll to the
   * appropriate section of the page.
   */
  @tracked isAutoScrolling = false;

  // Getters and setters

  // Lifecycle methods

  // Other methods
  /**
   * `handleCategoryResize` is used to bump the scroll position on menu pages
   * when menu categories resize. Resizing happens when categories lazy load and
   * can cause the user's viewport to shift down, making it hard to read the
   * menu.
   *
   * If the category is starts within the viewport or farther down the page,
   * changes in height can be ignored, because those changes won't make the
   * page feel as though it has shifted.
   *
   *    +-------+          +-------+
   *  +-|-------|-+      +-|-------|-+
   *  | +-------+ |      | +-------+ |
   *  |           |      |           |
   *  | +-------+ |      | +-------+ |
   *  | |       | |  =>  | |this is| |
   *  | +-------+ |      | | fine  | |
   *  |           |      | +-------+ |
   *  | +-------+ |      |           |
   *  +-|-------|-+      +-+-------+-+
   *    +-------+          |       |
   *                       +-------+
   *
   * However, categories that are partially or fully above the viewport need to
   * bump the scroll position.
   *
   *    +-------+          +-------+          +-------+
   *  +-|-------|-+      +-|-------|-+        |       |
   *  | +-------+ |      | |       | |      +-|-------|-+
   *  |           |      | +-------+ |      | +-------+ |
   *  | +-------+ |      |           |      |           |
   *  | |       | |  =>  | +-------+ |  =>  | +-------+ |
   *  | +-------+ |      | | oh no!| |      | |  ok!  | |
   *  |           |      | +-------+ |      | +-------+ |
   *  | +-------+ |      |           |      |           |
   *  +-|-------|-+      +-+-------+-+      | +-------+ |
   *    +-------+          |       |        +-|-------|-+
   *                       +-------+          +-------+
   *
   * @param categoryElement the element that has resized
   */
  handleCategoryResize(categoryElement: HTMLElement): void {
    const { height, top } = categoryElement.getBoundingClientRect();

    const oldHeight = this.categoryHeights.get(categoryElement) ?? height;

    this.categoryHeights.set(categoryElement, height);

    // if the category starts within the viewport or farther down the page,
    // changes in height can be ignored
    if (top >= 0) {
      return;
    }

    const delta = height - oldHeight;

    // if the category height hasn't changed--such as when the resize observer
    // is first attached--do nothing.
    if (!delta) {
      return;
    }

    // otherwise for categories that start above the viewport need to bump the
    // scroll position by the amount that their height changed.
    const scrollParent = this.getScrollParent(categoryElement as HTMLElement);

    if (scrollParent) {
      scrollParent.scrollTop += delta;
    }
  }

  /**
   * This method returns a promise that resolves when scrolling is no longer
   * occurring for the provided scrollable element.
   *
   * @param scrollable the scrollable element that should be observed for
   * changes. Defaults to the `<html>` element.
   * @returns a promise that resolves when scrolling is finished.
   */
  scrollStop(scrollable: Scrollable = document.documentElement): Promise<void> {
    return new Promise(resolve => {
      let { scrollLeft, scrollTop } = scrollable;
      let state = 'idle';
      let idleCount = 0;

      const tick = () => {
        const oldScrollLeft = scrollLeft;
        const oldScrollTop = scrollTop;
        ({ scrollLeft, scrollTop } = scrollable);

        switch (state) {
          // Native browser smooth scrolling will delay a few render frames
          // before starting, so we need to wait a few frames before watching
          // scrolling. However, it's also possible that the browser doesn't
          // support smooth scrolling, or that the scroll position was already
          // at its final destination, so if we idle too long we can consider
          // scrolling done.
          case 'idle':
            if (scrollLeft !== oldScrollLeft || scrollTop !== oldScrollTop) {
              state = 'scrolling';
            } else if (idleCount++ === 5) {
              state = 'done';
            }
            break; // keep ticking
          case 'scrolling':
            if (scrollLeft === oldScrollLeft && scrollTop === oldScrollTop) {
              state = 'done';
            }
            break; // keep ticking
          case 'done':
          default:
            resolve();
            return; // stop ticking
        }

        requestAnimationFrame(tick);
      };

      tick(); // start ticking
    });
  }

  /**
   * Disable scrolling the document and preserve the scroll position.
   */
  disableDocumentScroll(): void {
    const documentScroll = document.documentElement.scrollTop;
    document.documentElement.classList.add('disable-scroll');
    document.body.scrollTop = documentScroll;
  }

  /**
   * Re-enable scrolling the document and preserve the scroll position.
   */
  enableDocumentScroll(): void {
    const bodyScroll = document.body.scrollTop;
    document.documentElement.classList.remove('disable-scroll');
    document.documentElement.scrollTop = bodyScroll;
  }

  /**
   * Gets the closest parent element of the specified element with an overflow
   * style that allows scrolling.
   *
   * Note that this is intentionally different from
   * `ScrollService.getScrollParent` which searches for the nearest parent
   * element where the scroll height is larger than the client height.
   *
   * @param el the element to start at
   * @returns the nearest parent element with an overflow style that allows
   * scrolling, or `undefined` if no such parent exists.
   */
  getOverflowableParent(el: HTMLElement): HTMLElement | undefined {
    for (const parent of getParents(el)) {
      if (ScrollService.overflowableValues.has(getComputedStyle(parent).overflow)) {
        return parent;
      }
    }
    return undefined;
  }

  /**
   * Get the first scrollable parent of the specified element
   *
   * @param el the element
   * @returns the scrollable parent element
   */
  getScrollParent = (el: HTMLElement): HTMLElement | undefined => {
    if (this.scrollParents.has(el)) {
      const scrollParent = this.scrollParents.get(el);
      if (scrollParent) {
        return scrollParent;
      }
    }
    for (const parent of getParents(el)) {
      if (parent.scrollHeight > parent.clientHeight) {
        this.scrollParents.set(el, parent);
        return parent;
      }
    }

    return undefined;
  };

  /**
   * Scroll into view the first element that matches the given selector.
   * @param selector
   * @returns
   */
  scrollIntoView = (selector: string, { alignToTop = false, waitCount = 2, retryCount = 3 }) => {
    this._scrollIntoViewOptions = { selector, alignToTop, waitCount, retryCount };
    scheduleOnce('afterRender', this, this._scrollIntoView);
  };
  private _scrollIntoView() {
    if (this._scrollIntoViewOptions.waitCount > 0) {
      // countdown to wait
      this._scrollIntoViewOptions.waitCount--;
      scheduleOnce('afterRender', this, this._scrollIntoView);
    } else {
      const scrollToElement = document.querySelector(
        this._scrollIntoViewOptions.selector
      ) as HTMLElement;
      if (scrollToElement) {
        scrollToElement?.scrollIntoView(this._scrollIntoViewOptions.alignToTop);
        this._scrollIntoViewOptions.retryCount = 0;
      } else if (this._scrollIntoViewOptions.retryCount > 0) {
        // countdown and retry
        this._scrollIntoViewOptions.retryCount--;
        scheduleOnce('afterRender', this, this._scrollIntoView);
      }
    }
  }

  // Tasks

  // Actions and helpers
  @action
  private resizeCategories(entries: ResizeObserverEntry[]) {
    for (const { target } of entries) {
      this.handleCategoryResize(target as HTMLElement);
    }
  }

  private static overflowableValues = new Set(['auto', 'hidden', 'scroll']);
}

declare module '@ember/service' {
  interface Registry {
    scroll: ScrollService;
  }
}
