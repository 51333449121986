import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import { MAX_RECIPIENT_NAME_LENGTH } from 'mobile-web/lib/customer';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import ErrorService from 'mobile-web/services/error';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

class Model {
  @tracked fullName = '';
  @tracked showJoinNewGroupModal = false;
}

interface Args {
  slug: string;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class GroupOrderJoinRoute extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service groupOrder!: GroupOrderService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service vendor!: VendorService;
  @service error!: ErrorService;

  // Untracked properties
  style = style;
  maxFullNameLength = MAX_RECIPIENT_NAME_LENGTH;

  // Tracked properties
  @tracked model!: Model;

  // Getters and setters
  get isSubmitDisabled(): boolean {
    return !this.model.fullName;
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.model = new Model();
    this.model.fullName = this.groupOrder.currentUserName;
    this.model.showJoinNewGroupModal =
      this.groupOrder.hasGroupOrder &&
      this.groupOrder.isParticipantMode &&
      this.router.currentRoute?.queryParams?.hasOwnProperty('groupOrderId') &&
      this.router.currentRoute?.queryParams?.groupOrderId !== this.groupOrder.groupOrderId;
  }

  // Other methods
  goToMenu() {
    this.router.transitionTo('menu.vendor', this.args.slug!, {
      queryParams: { forceReload: true },
    });
  }

  // Tasks
  joinTask = taskFor(this.join);
  @task *join(): TaskGenerator<void> {
    if (!this.isSubmitDisabled) {
      try {
        if (!this.session.isLoggedIn) {
          yield this.session.setGuestUser({
            fullName: this.model.fullName,
            firstName: '',
            lastName: '',
            emailAddress: '',
            contactNumber: '',
            optIn: false,
          });
        }

        yield this.groupOrder.joinGroupOrder(this.args.slug, this.model.fullName, false);
        this.analytics.trackEvent(AnalyticsEvents.GroupOrderJoinGroupOrder);
        this.goToMenu();
      } catch (ex) {
        this.error.reportError(ex);
      }
    }
  }

  // Actions and helpers
  @action
  async confirm(e: Event) {
    e.preventDefault();
    await this.joinTask.perform();
  }

  @action
  async onLoad() {
    if (this.session.isLoggedIn) {
      await this.joinTask.perform();
    }
  }

  @action
  cancel() {
    this.groupOrder.groupOrderToJoin = undefined;
    this.goToMenu();
  }

  @action
  closeJoinNewGroupModal() {
    this.model.showJoinNewGroupModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::GroupOrder::JoinRoute': typeof GroupOrderJoinRoute;
  }
}
