import { action, computed } from '@ember/object';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import IntlService from 'ember-intl/services/intl';

import { Variant } from 'mobile-web/components/button';
import { FORM_FIELD_OPTIONAL_LABEL } from 'mobile-web/lib/strings';
import { classes } from 'mobile-web/lib/utilities/classes';
import { guids } from 'mobile-web/lib/utilities/guids';
import { Autocomplete, HtmlBool, InputMode, TabIndex } from 'mobile-web/lib/utilities/html-types';
import isSome from 'mobile-web/lib/utilities/is-some';
import { ValidationMessage } from 'mobile-web/lib/validation';
import { ContentString } from 'mobile-web/services/content';
import FocusManagerService, { FocusData } from 'mobile-web/services/focus-manager';

import style from './index.m.scss';

export type Size = 'standard' | 'small' | 'large';
export type Type = 'email' | 'password' | 'select' | 'text' | 'textarea';
type TagType = 'input' | 'select' | 'textarea';
interface Args {
  // Required arguments
  label: ContentString;
  name: string;

  // Optional arguments
  autocapitalize?: string;
  autocomplete?: Autocomplete;
  autofocus?: HtmlBool;

  buttonData?: {
    icon?: string;
    label: string;
    onClick: () => void;
    size?: Size;
    testSelector?: string;
    variant?: EnumOrValue<Variant>;
    class?: string;
    iconClass?: string;
    disabled?: boolean;
  };

  checked?: boolean;
  class?: string;
  isDirty?: boolean;
  disabled?: boolean;
  fieldClass?: string;
  focusCategory?: string;
  formatValue?: (value?: string) => string;
  formGroup?: boolean;
  hasRequirement?: boolean;
  helpMessage?: ContentString;
  hideLabel?: boolean;
  icon?: string;
  iconRight?: string;
  inlineLabel?: boolean; // Only required when you want an inline label without an icon.
  inputMode?: InputMode;
  inputSize?: number;
  inputValue?: string;
  labelClass?: string;
  tabIndex?: TabIndex;
  max?: number;
  maxlength?: number;
  min?: number;
  minlength?: number;
  novalidate?: boolean;
  optional?: boolean;
  onFocus?: () => void;
  onInput?: (value?: string, previousValue?: string) => void;
  overrideInput?: boolean;
  pattern?: string;
  placeholder?: ContentString;
  readonly?: boolean;
  required?: boolean;
  responsiveLabel?: boolean;
  reverseLayout?: boolean;
  setValue?: (value?: string) => void;
  showCount?: boolean;
  size?: Size;
  ariaDescribedBy?: string;
  validate?: () => void;
  /**
   * @summary
   * determines if whitespace should be trimmed from the field value automatically.
   * use `this.trimOnChange`
   *
   * @default true
   */
  trimOnChange?: boolean;
  type?: Type;
  // **DO NOT USE** this is serving as a component level feature flag that I am
  // adding so I can break the form field validation refactor up into multiple tickets
  // and finally finish this ticket T_T
  useValidationRefactor?: boolean;
  useAltErrorStyle?: boolean;
  validationMessages?: ValidationMessage[];
  onFocusOut?: Action<[]>;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    // eslint-disable-next-line no-use-before-define
    default: [FormField] | [];
    requirement: [
      {
        id: string;
        focused: boolean;
        iconClass: string;
        messageClass: string;
        textClass: string;
        validClass: string;
      }
    ];
  };
}

export default class FormField extends Component<Signature> {
  // Service injections
  @service focusManager!: FocusManagerService;
  @service intl!: IntlService;

  // Untracked properties
  focusData?: FocusData;
  style = style;
  optionalIndicatorText = FORM_FIELD_OPTIONAL_LABEL;

  // Tracked properties
  /**
   * Exists solely to trigger recomputes on getters that depend on @validationMessages.
   * Currently, this component breaks data down actions up by mutating @validationMessages.
   * Fixing that would be a bigger refactor, so we're putting off for now.
   */
  @tracked validationMessagesCounter = 0;
  @tracked focused = false;
  @tracked _isDirty: boolean | undefined = false;

  // Getters and setters
  get aria() {
    return {
      describedby: this.ariaDescribedby,
      label: this.args.hideLabel ? this.args.label : undefined,
      invalid: this.invalid?.toString(),
    };
  }

  get ariaDescribedby(): string | undefined {
    const ids = [];

    if (this.args.ariaDescribedBy) {
      ids.push(this.args.ariaDescribedBy);
    }

    if (this.args.helpMessage) {
      ids.push(this.ids.help);
    }

    if (this.invalid) {
      ids.push(this.ids.error);
    } else if (this.args.hasRequirement) {
      ids.push(this.ids.requirement);
    }

    if (this.showRemainingCharacterCount) {
      ids.push(this.ids.characterCount);
    }

    return ids.length ? ids.join(' ') : undefined;
  }

  get characterCountLabel() {
    return this.intl.t('mwc.formField.characterCount', {
      remaining: this.remainingCharacterCount,
    });
  }

  get buttonClass(): string {
    const cls = [style.button];
    if (this.args.buttonData?.variant) {
      cls.push(style[`button--` + this.args.buttonData.variant]);
    }
    if (this.args.buttonData?.class) {
      cls.push(this.args.buttonData.class);
    }
    return classes(...cls);
  }

  get buttonIconClass(): string {
    const cls = [style.icon];
    if (this.args.buttonData?.iconClass) {
      cls.push(this.args.buttonData.iconClass);
    }
    return classes(...cls);
  }

  @computed('args.validationMessages.[]')
  get firstValidationMessage() {
    return this.args.validationMessages?.[0]?.message;
  }

  get ids() {
    return guids(this, 'characterCount', 'error', 'help', 'input', 'requirement');
  }

  get inline(): boolean {
    return this.args.inlineLabel || isSome(this.args.icon);
  }

  get inlineLabelTextClass(): string {
    return classes(this.style.inlineLabelText, {
      [this.style.responsive]: this.args.responsiveLabel,
    });
  }

  get inputClass(): string {
    return classes(
      {
        [this.style.textInput]: !this.isSelect && !this.inline,
        [this.style.inlineTextInput]: !this.isSelect && this.inline,
        [this.style.selectInput]: this.isSelect && !this.inline,
        [this.style.inlineSelectInput]: this.isSelect && this.inline,
      },
      this.args.fieldClass
    );
  }

  get inputTag(): TagType {
    switch (this.type) {
      case 'select':
      case 'textarea':
        return this.type;
      default:
        return 'input';
    }
  }

  get invalid(): boolean {
    if (this.args.useValidationRefactor) {
      return (
        this.validationMessagesCounter > -1 &&
        !this.args.novalidate &&
        (this.args.validationMessages?.length ?? 0) > 0 &&
        this.isDirty!
      );
    }
    return (
      this.validationMessagesCounter > -1 &&
      !this.args.novalidate &&
      (this.args.validationMessages?.length ?? 0) > 0
    );
  }

  get isDirty() {
    return this._isDirty || this.args.isDirty;
  }

  get isSelect(): boolean {
    return this.type === 'select';
  }

  get labelClassName(): string {
    return classes(
      {
        [this.style.inlineLabel]: this.inline,
        [this.style.hiddenLabel]: !this.inline && this.args.hideLabel,
        [this.style.label]: !this.inline && !this.args.hideLabel,
      },
      this.args.labelClass
    );
  }

  get labelInputContainerClass(): string {
    return this.inline ? this.style.inlineLabelInputContainer : this.style.labelInputContainer;
  }

  get remainingCharacterCount(): number {
    const characterCount = this.args.inputValue?.length ?? 0;
    return this.args.maxlength ? this.args.maxlength - characterCount : 0;
  }

  get showRemainingCharacterCount(): boolean {
    return Boolean(this.args.showCount && this.args.maxlength);
  }

  get tagClass(): string {
    return classes(
      this.style.container,
      this.args.class,
      {
        [this.style.disabled]: this.args.disabled,

        [this.style.focused]: this.focused,

        [this.style.inline]: this.inline,
        [this.style.standard]: !this.inline,

        [this.style.formGroup]: this.args.formGroup,
        [this.style.reverse]: this.args.reverseLayout,

        [this.style.error]: this.invalid && !this.args.useAltErrorStyle,
        [this.style.errorAlt]: this.invalid && this.args.useAltErrorStyle,
      },
      this.args.size ? this.style[this.args.size] : this.style.standard
    );
  }

  get trimOnChange() {
    return this.args.trimOnChange ?? true;
  }

  get type() {
    return this.args.type ?? 'text';
  }

  // Lifecycle methods

  // Other methods

  clearMessages() {
    if (isSome(this.args.validationMessages)) {
      // TODO: update this to use unidirectional dataflow
      this.args.validationMessages.clear();
      this.validationMessagesCounter++;
    }
  }

  // Tasks

  // Actions and helpers

  @action
  register(input: HTMLElement) {
    if (this.args.focusCategory) {
      this.focusData = {
        allowFocus: () => this.invalid,
        onFocus: this.args.onFocus,
        target: input,
      };
      this.focusManager.register(this.args.focusCategory, this.focusData);
    }
  }

  @action
  deregister() {
    if (this.args.focusCategory && this.focusData) {
      this.focusManager.deregister(this.args.focusCategory, this.focusData);
    }
  }

  @action
  focusIn() {
    if (!this.args.disabled) {
      next(this, () => {
        this.focused = true;
      });
    }
  }

  @action
  focusOut() {
    if (!this.args.disabled) {
      next(this, () => {
        this.focused = false;
      });
      this.args.onFocusOut?.();
    }
  }

  @action
  handleButtonClick() {
    this.args.buttonData?.onClick?.();
  }

  @action
  onChange() {
    this.args.validate?.();
    this._isDirty = true;
  }

  @action
  onInvalid(e: Event) {
    e.preventDefault();
  }

  @action
  setFocus() {
    this.args.onFocus?.();
  }

  @action
  setValue(value?: string) {
    if (!this.args.useValidationRefactor) {
      this.clearMessages();
    }

    const trimmedValue = this.trimOnChange ? value?.trim() : value;

    const formattedValue = this.args.formatValue
      ? this.args.formatValue(trimmedValue)
      : trimmedValue;

    this.args.setValue?.(formattedValue);
  }

  @action
  setValueImmediate(value?: string, previousValue?: string) {
    if (!this.args.useValidationRefactor) {
      this.clearMessages();
    }

    const formattedValue = this.args.formatValue ? this.args.formatValue(value) : value;

    this.args.onInput?.(formattedValue, previousValue);

    this.args.validate?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FormField: typeof FormField;
  }
}
