import Controller from '@ember/controller';
import { computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { Model } from 'mobile-web/routes/menu/vendor';
import { ProductsRouteModel } from 'mobile-web/routes/menu/vendor/products';

export default class MenuVendorController extends Controller {
  @service router!: RouterService;

  @computed('router.currentRouteName')
  get isProductPage(): boolean {
    return this.router.currentRouteName === 'menu.vendor.products';
  }

  @computed('isProductPage', 'router.currentRoute.attributes')
  get isFullScreenProductPage(): boolean {
    if (!this.isProductPage) {
      return false;
    }

    // @ts-ignore - Ember DefinitelyTyped types are incomplete here. The router.currentRoute
    // property here _should be_ RouteInfo | RouteInfoWithAttributes, but is instead just
    // RouteInfo, so it lacks `.attributes`. This could be fixed with a upstream type change,
    // but for now just gonna go with the ts-ignore fix.
    const routeModel: ProductsRouteModel | undefined = this.router.currentRoute.attributes;
    const displayInModal = routeModel?.displayInModal ?? false;
    return !displayInModal;
  }

  model!: Model;

  queryParams = [
    'showInfoModal',
    'handoff',
    { forceReload: 'forceReload' },
    { openCriteria: 'open-criteria' },

    // OnPremise related:
    { multiOrder: 'multiorder' }, //do not delete because legacy QR codes
    { closedCheck: 'closedcheck' },
    { tablePosRef: 'tableposref' },
    { openCheck: 'opencheck' },
    { clearOnPrem: 'clearonprem' },

    // The ones below are specific URL parameters that brands don't want cleaned out
    // of the URL when the menu page loads
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    '_ga',
    '_gl',
  ];

  @tracked showInfoModal?: string;
  @tracked handoff?: string;
  @tracked forceReload?: boolean;
  @tracked openCriteria?: string;
  @tracked skipPreCheck = false;

  @tracked multiOrder?: string; //do not delete because legacy QR codes
  @tracked closedCheck?: string;
  @tracked tablePosRef?: string;
  @tracked openCheck?: string;
  @tracked clearOnPrem?: string;
}
