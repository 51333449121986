import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { CustomField } from 'mobile-web/lib/custom-field';
import { roundUpToNext } from 'mobile-web/lib/utilities/math';
import OptionGroup from 'mobile-web/models/option-group';
import ChannelService from 'mobile-web/services/channel';

export default class ChoiceModel extends DS.Model {
  @service channel!: ChannelService;

  @DS.attr('string')
  name!: string;
  /**
   * Don't use this. Its value is always the same as `name`. Leaving it here
   * because there is legacy code that uses it. Once the legacy code has been deleted,
   * this can also be deleted.
   */
  @DS.attr('string')
  displayName!: string;
  @DS.attr('number')
  priceDifference?: number;
  @DS.attr('string')
  priceDifferenceOverrideLabel?: string;
  @DS.attr('boolean')
  isDefault!: boolean;
  @DS.attr('boolean')
  isBaseProduct!: boolean;
  @DS.attr('boolean')
  isSelected!: boolean;
  @DS.attr('number', { defaultValue: 0 })
  quantity!: number;
  @DS.attr('boolean')
  hasCustomFields!: boolean;
  @DS.attr('boolean')
  hasInlineChoices!: boolean;
  @DS.attr('array')
  customFieldValues!: CustomField[];
  @DS.attr('string')
  calorieLabel!: string;
  @DS.attr('array', { defaultValue: () => [] })
  labels!: Array<string>;
  @DS.attr('object')
  displayGroup!: { optionName: string; groupName: string };
  @DS.attr('boolean')
  isDisabled!: boolean;
  @DS.attr('object')
  image!: { groupName: string; filename: string };

  // This relationship is not returned from the back end, but is generated by
  // Ember Data as an inverse relationship, so the option group must exist already -
  // unless we are dealing with a hidden choice. A hidden choice has no parent
  // option group, hence the nullable.
  @DS.belongsTo('option-group', { inverse: 'choices', async: false })
  optionGroup?: OptionGroup;

  @DS.hasMany('option-group', { inverse: 'parentChoice' })
  optionGroups!: DS.PromiseManyArray<OptionGroup>;

  get minQuantity(): number {
    const quantity = Math.max(this.quantity, this.optionGroup?.minChoiceQuantity ?? 0, 1);
    const stepSize = this.optionGroup?.choiceQuantityIncrement ?? 1;

    return roundUpToNext(stepSize, quantity);
  }

  get defaultQuantity(): number {
    const preferredStartingValue = this.channel.settings?.defaultChoicesToMax
      ? this.optionGroup?.maxChoiceQuantity || this.minQuantity
      : this.minQuantity;

    return this.isDefault ? preferredStartingValue : 0;
  }

  get groupedName(): string {
    return this.displayGroup?.groupName ?? this.displayName;
  }
}
