/**
 * OLO:
 *
 * This code was shamelessly coppy-pasted from text-mask/text-mask, which is dead.
 * It was triggering deprecations by importing from `@ember/component/text-field`.
 * The fix is to change the import to `@ember/legacy-built-in-components`.
 *
 * UPDATE: @ember/legacy-built-in-components imports became undefined after
 * upgrading to Ember 4.0, so we had to copy-paste that too :(
 *
 * Eventually we should switch to a different library or roll our own implementation.
 * But not today.
 *
 * Since this is a copy-paste, I've left (almost) everything as it was.
 * However, I dumped everything into this file to keep all the ugly in one place.
 * This meant I had to change some variable names that were conflicting.
 *
 * Finally, all comments that contain `OLO:` and all eslint comments are from us.
 * Everything else is from the source.
 */

/* eslint-disable consistent-return */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/require-tagless-components */
/* eslint-disable no-null/no-null */
/* eslint-disable prefer-const */

import { MUTABLE_CELL } from '@ember/-internals/views';
import Component from '@ember/component';
import { assert, deprecate } from '@ember/debug';
import { SEND_ACTION } from '@ember/deprecated-features';
import { get, getProperties, computed, set } from '@ember/object';
import { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
import { schedule } from '@ember/runloop';

/**
 * OLO: Source: https://github.com/emberjs/ember-legacy-built-in-components/blob/v0.4.1/addon/components/_internals.ts
 * OK, this is weird. In the source, the definition for `context` is actually
 * commented out. But it's imported by _target-action-support.js. There's even
 * an open issue for it: https://github.com/emberjs/ember-legacy-built-in-components/issues/18
 * I have no idea how this ever built, but in the meantime this works.
 */
const context = (function (global, Ember) {
  return Ember === undefined
    ? { imports: global, exports: global, lookup: global }
    : {
        // import jQuery
        imports: Ember.imports || global,
        // export Ember
        exports: Ember.exports || global,
        // search for Namespaces
        lookup: Ember.lookup || global,
      };
})(window, window.Ember);

/**
 * OLO: Source: https://github.com/emberjs/ember-legacy-built-in-components/blob/v0.4.1/addon/mixins/_target_action_support.js
 */
/**
`Ember.TargetActionSupport` is a mixin that can be included in a class
to add a `triggerAction` method with semantics similar to the Handlebars
`{{action}}` helper. In normal Ember usage, the `{{action}}` helper is
usually the best choice. This mixin is most often useful when you are
doing more complex event handling in Components.
@class TargetActionSupport
@namespace Ember
@extends Mixin
@private
*/
const TargetActionSupport = Mixin.create({
  target: null,
  action: null,
  actionContext: null,

  actionContextObject: computed('actionContext', function () {
    let actionContext = get(this, 'actionContext');

    if (typeof actionContext === 'string') {
      let value = get(this, actionContext);
      if (value === undefined) {
        value = get(context.lookup, actionContext);
      }
      return value;
    }
    return actionContext;
  }),

  /**
  Send an `action` with an `actionContext` to a `target`. The action, actionContext
  and target will be retrieved from properties of the object. For example:
  ```javascript
  import { alias } from '@ember/object/computed';
  App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
    target: alias('controller'),
    action: 'save',
    actionContext: alias('context'),
    click() {
      this.triggerAction(); // Sends the `save` action, along with the current context
                            // to the current controller
    }
  });
  ```
  The `target`, `action`, and `actionContext` can be provided as properties of
  an optional object argument to `triggerAction` as well.
  ```javascript
  App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
    click() {
      this.triggerAction({
        action: 'save',
        target: this.get('controller'),
        actionContext: this.get('context')
      }); // Sends the `save` action, along with the current context
          // to the current controller
    }
  });
  ```
  The `actionContext` defaults to the object you are mixing `TargetActionSupport` into.
  But `target` and `action` must be specified either as properties or with the argument
  to `triggerAction`, or a combination:
  ```javascript
  import { alias } from '@ember/object/computed';
  App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
    target: alias('controller'),
    click() {
      this.triggerAction({
        action: 'save'
      }); // Sends the `save` action, along with a reference to `this`,
          // to the current controller
    }
  });
  ```
  @method triggerAction
  @param opts {Object} (optional, with the optional keys action, target and/or actionContext)
  @return {Boolean} true if the action was sent successfully and did not return false
  @private
  */
  triggerAction(opts = {}) {
    let { action, target, actionContext } = opts;
    action = action || get(this, 'action');
    target = target || getTarget(this);

    if (actionContext === undefined) {
      actionContext = get(this, 'actionContextObject') || this;
    }

    if (target && action) {
      let ret;

      if (target.send) {
        ret = target.send(...[action].concat(actionContext));
      } else {
        assert(
          `The action '${action}' did not exist on ${target}`,
          typeof target[action] === 'function'
        );
        ret = target[action](...[].concat(actionContext));
      }

      if (ret !== false) {
        return true;
      }
    }

    return false;
  },
});

function getTarget(instance) {
  let target = get(instance, 'target');
  if (target) {
    if (typeof target === 'string') {
      let value = get(instance, target);
      if (value === undefined) {
        value = get(context.lookup, target);
      }

      return value;
    }
    return target;
  }

  if (instance._target) {
    return instance._target;
  }

  return null;
}

/**
 * OLO: Source: https://github.com/emberjs/ember-legacy-built-in-components/blob/v0.4.1/addon/mixins/text-support.js
 */
const KEY_EVENTS = {
  Enter: 'insertNewline',
  Escape: 'cancel',
};

/**
  `TextSupport` is a shared mixin used by both `TextField` and
  `TextArea`. `TextSupport` adds a number of methods that allow you to
  specify a controller action to invoke when a certain event is fired on your
  text field or textarea. The specified controller action would get the current
  value of the field passed in as the only argument unless the value of
  the field is empty. In that case, the instance of the field itself is passed
  in as the only argument.
  Let's use the pressing of the escape key as an example. If you wanted to
  invoke a controller action when a user presses the escape key while on your
  field, you would use the `escape-press` attribute on your field like so:
  ```handlebars
    {{! application.hbs}}
    {{input escape-press='alertUser'}}
  ```
  ```javascript
      import Application from '@ember/application';
      import Controller from '@ember/controller';
      App = Application.create();
      App.ApplicationController = Controller.extend({
        actions: {
          alertUser: function ( currentValue ) {
            alert( 'escape pressed, current value: ' + currentValue );
          }
        }
      });
  ```
  The following chart is a visual representation of what takes place when the
  escape key is pressed in this scenario:
  ```
  The Template
  +---------------------------+
  |                           |
  | escape-press='alertUser'  |
  |                           |          TextSupport Mixin
  +----+----------------------+          +-------------------------------+
       |                                 | cancel method                 |
       |      escape button pressed      |                               |
       +-------------------------------> | checks for the `escape-press` |
                                         | attribute and pulls out the   |
       +-------------------------------+ | `alertUser` value             |
       |     action name 'alertUser'     +-------------------------------+
       |     sent to controller
       v
  Controller
  +------------------------------------------ +
  |                                           |
  |  actions: {                               |
  |     alertUser: function( currentValue ){  |
  |       alert( 'the esc key was pressed!' ) |
  |     }                                     |
  |  }                                        |
  |                                           |
  +-------------------------------------------+
  ```
  Here are the events that we currently support along with the name of the
  attribute you would need to use on your field. To reiterate, you would use the
  attribute name like so:
  ```handlebars
    {{input attribute-name='controllerAction'}}
  ```
  ```
  +--------------------+----------------+
  |                    |                |
  | event              | attribute name |
  +--------------------+----------------+
  | new line inserted  | insert-newline |
  |                    |                |
  | enter key pressed  | enter          |
  |                    |                |
  | cancel key pressed | escape-press   |
  |                    |                |
  | focusin            | focus-in       |
  |                    |                |
  | focusout           | focus-out      |
  |                    |                |
  | keypress           | key-press      |
  |                    |                |
  | keyup              | key-up         |
  |                    |                |
  | keydown            | key-down       |
  +--------------------+----------------+
  ```
  @class TextSupport
  @namespace Ember
  @uses Ember.TargetActionSupport
  @extends Mixin
  @private
*/
const TextSupportMixin = Mixin.create(TargetActionSupport, {
  value: '',

  attributeBindings: [
    'autocapitalize',
    'autocorrect',
    'autofocus',
    'disabled',
    'form',
    'maxlength',
    'minlength',
    'placeholder',
    'readonly',
    'required',
    'selectionDirection',
    'spellcheck',
    'tabindex',
    'title',
  ],
  placeholder: null,
  disabled: false,
  maxlength: null,

  init() {
    this._super(...arguments);
    this.on('paste', this, this._elementValueDidChange);
    this.on('cut', this, this._elementValueDidChange);
    this.on('input', this, this._elementValueDidChange);
  },

  /**
    Whether the `keyUp` event that triggers an `action` to be sent continues
    propagating to other views.
    By default, when the user presses the return key on their keyboard and
    the text field has an `action` set, the action will be sent to the view's
    controller and the key event will stop propagating.
    If you would like parent views to receive the `keyUp` event even after an
    action has been dispatched, set `bubbles` to true.
    @property bubbles
    @type Boolean
    @default false
    @private
  */
  bubbles: false,

  interpretKeyEvents(event) {
    let method = KEY_EVENTS[event.key];

    this._elementValueDidChange();
    if (method) {
      return this[method](event);
    }
  },

  _elementValueDidChange() {
    set(this, 'value', this.element.value);
    this.element.dispatchEvent(new Event('inputmasked'));
  },

  change(event) {
    this._elementValueDidChange(event);
  },

  /**
    Allows you to specify a controller action to invoke when either the `enter`
    key is pressed or, in the case of the field being a textarea, when a newline
    is inserted. To use this method, give your field an `insert-newline`
    attribute. The value of that attribute should be the name of the action
    in your controller that you wish to invoke.
    For an example on how to use the `insert-newline` attribute, please
    reference the example near the top of this file.
    @method insertNewline
    @param {Event} event
    @private
  */
  insertNewline(event) {
    sendAction('enter', this, event);
    sendAction('insert-newline', this, event);
  },

  /**
    Allows you to specify a controller action to invoke when the escape button
    is pressed. To use this method, give your field an `escape-press`
    attribute. The value of that attribute should be the name of the action
    in your controller that you wish to invoke.
    For an example on how to use the `escape-press` attribute, please reference
    the example near the top of this file.
    @method cancel
    @param {Event} event
    @private
  */
  cancel(event) {
    sendAction('escape-press', this, event);
  },

  /**
    Allows you to specify a controller action to invoke when a field receives
    focus. To use this method, give your field a `focus-in` attribute. The value
    of that attribute should be the name of the action in your controller
    that you wish to invoke.
    For an example on how to use the `focus-in` attribute, please reference the
    example near the top of this file.
    @method focusIn
    @param {Event} event
    @private
  */
  focusIn(event) {
    sendAction('focus-in', this, event);
  },

  /**
    Allows you to specify a controller action to invoke when a field loses
    focus. To use this method, give your field a `focus-out` attribute. The value
    of that attribute should be the name of the action in your controller
    that you wish to invoke.
    For an example on how to use the `focus-out` attribute, please reference the
    example near the top of this file.
    @method focusOut
    @param {Event} event
    @private
  */
  focusOut(event) {
    this._elementValueDidChange(event);
    sendAction('focus-out', this, event);
  },

  /**
    Allows you to specify a controller action to invoke when a key is pressed.
    To use this method, give your field a `key-press` attribute. The value of
    that attribute should be the name of the action in your controller you
    that wish to invoke.
    For an example on how to use the `key-press` attribute, please reference the
    example near the top of this file.
    @method keyPress
    @param {Event} event
    @private
  */
  keyPress(event) {
    sendAction('key-press', this, event);
  },

  /**
    Allows you to specify a controller action to invoke when a key-up event is
    fired. To use this method, give your field a `key-up` attribute. The value
    of that attribute should be the name of the action in your controller
    that you wish to invoke.
    For an example on how to use the `key-up` attribute, please reference the
    example near the top of this file.
    @method keyUp
    @param {Event} event
    @private
  */
  keyUp(event) {
    this.interpretKeyEvents(event);
    sendAction('key-up', this, event);
  },

  /**
    Allows you to specify a controller action to invoke when a key-down event is
    fired. To use this method, give your field a `key-down` attribute. The value
    of that attribute should be the name of the action in your controller that
    you wish to invoke.
    For an example on how to use the `key-down` attribute, please reference the
    example near the top of this file.
    @method keyDown
    @param {Event} event
    @private
  */
  keyDown(event) {
    sendAction('key-down', this, event);
  },
});

// In principle, this shouldn't be necessary, but the legacy
// sendAction semantics for TextField are different from
// the component semantics so this method normalizes them.
function sendAction(eventName, view, event) {
  let action = get(view, `attrs.${eventName}`);
  if (action !== null && typeof action === 'object' && action[MUTABLE_CELL] === true) {
    action = action.value;
  }

  if (action === undefined) {
    action = get(view, eventName);
  }

  let value = view.value;

  if (SEND_ACTION && typeof action === 'string') {
    let message = `Passing actions to components as strings (like \`<Input @${eventName}="${action}" />\`) is deprecated. Please use closure actions instead (\`<Input @${eventName}={{action "${action}"}} />\`).`;

    deprecate(message, false, {
      id: 'ember-component.send-action',
      until: '4.0.0',
      url: 'https://emberjs.com/deprecations/v3.x#toc_ember-component-send-action',
      for: 'ember-source',
      since: {
        enabled: '3.4.0',
      },
    });

    view.triggerAction({
      action,
      actionContext: [value, event],
    });
  } else if (typeof action === 'function') {
    action(value, event);
  }

  if (action && !view.bubbles) {
    event.stopPropagation();
  }
}

/**
 * OLO: Source: https://github.com/emberjs/ember-legacy-built-in-components/blob/v0.4.1/addon/components/_has-dom.ts
 */
// check if window exists and actually is the global
const hasDOM =
  typeof self === 'object' &&
  self !== null &&
  self.Object === Object &&
  typeof Window !== 'undefined' &&
  self.constructor === Window &&
  typeof document === 'object' &&
  document !== null &&
  self.document === document &&
  typeof location === 'object' &&
  location !== null &&
  self.location === location &&
  typeof history === 'object' &&
  history !== null &&
  self.history === history &&
  typeof navigator === 'object' &&
  navigator !== null &&
  self.navigator === navigator &&
  typeof navigator.userAgent === 'string';

/**
 * OLO: Source: https://github.com/emberjs/ember-legacy-built-in-components/blob/v0.4.1/addon/components/text-field.ts
 */
const inputTypes = hasDOM ? Object.create(null) : null;
function canSetTypeOfInput(type) {
  // if running in outside of a browser always return
  // the original type
  if (!hasDOM) {
    return Boolean(type);
  }

  if (type in inputTypes) {
    return inputTypes[type];
  }

  let inputTypeTestElement = document.createElement('input');

  try {
    inputTypeTestElement.type = type;
  } catch (e) {
    // ignored
  }

  return (inputTypes[type] = inputTypeTestElement.type === type);
}

/**
  The internal class used to create text inputs when the `Input` component is used with `type` of `text`.
  See [Ember.Templates.components.Input](/ember/release/classes/Ember.Templates.components/methods/Input?anchor=Input) for usage details.
  ## Layout and LayoutName properties
  Because HTML `input` elements are self closing `layout` and `layoutName`
  properties will not be applied.
  @class TextField
  @extends Component
  @uses Ember.TextSupport
  @public
*/
const TextField = Component.extend(TextSupportMixin, {
  /**
    By default, this component will add the `ember-text-field` class to the component's element.
    @property classNames
    @type Array | String
    @default ['ember-text-field']
    @public
   */
  classNames: ['ember-text-field'],
  tagName: 'input',

  /**
    By default this component will forward a number of arguments to attributes on the the
    component's element:
    * accept
    * autocomplete
    * autosave
    * dir
    * formaction
    * formenctype
    * formmethod
    * formnovalidate
    * formtarget
    * height
    * inputmode
    * lang
    * list
    * type
    * max
    * min
    * multiple
    * name
    * pattern
    * size
    * step
    * value
    * width
    When invoked with `{{input type="text"}}`, you can only customize these attributes. When invoked
    with `<Input @type="text" />`, you can just use HTML attributes directly.
    @property attributeBindings
    @type Array | String
    @default ['accept', 'autocomplete', 'autosave', 'dir', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'type', 'max', 'min', 'multiple', 'name', 'pattern', 'size', 'step', 'value', 'width']
    @public
  */
  attributeBindings: [
    'accept',
    'autocomplete',
    'autosave',
    'dir',
    'formaction',
    'formenctype',
    'formmethod',
    'formnovalidate',
    'formtarget',
    'height',
    'inputmode',
    'lang',
    'list',
    'type', // needs to be before min and max. See #15675
    'max',
    'min',
    'multiple',
    'name',
    'pattern',
    'size',
    'step',
    'value',
    'width',
  ],

  /**
    As the user inputs text, this property is updated to reflect the `value` property of the HTML
    element.
    @property value
    @type String
    @default ""
    @public
  */
  value: '',

  /**
    The `type` attribute of the input element.
    @property type
    @type String
    @default "text"
    @public
  */
  type: computed({
    get() {
      return 'text';
    },

    set(_key, value) {
      let type = 'text';

      if (canSetTypeOfInput(value)) {
        type = value;
      }

      return type;
    },
  }),

  /**
    The `size` of the text field in characters.
    @property size
    @type String
    @default null
    @public
  */
  size: null,

  /**
    The `pattern` attribute of input element.
    @property pattern
    @type String
    @default null
    @public
  */
  pattern: null,

  /**
    The `min` attribute of input element used with `type="number"` or `type="range"`.
    @property min
    @type String
    @default null
    @since 1.4.0
    @public
  */
  min: null,

  /**
    The `max` attribute of input element used with `type="number"` or `type="range"`.
    @property max
    @type String
    @default null
    @since 1.4.0
    @public
  */
  max: null,
});

TextField.toString = () => '@ember/component/text-field';

/**
 * OLO: Source: https://github.com/text-mask/text-mask/blob/master/core/src/constants.js
 */
const defaultPlaceholderChar = '_';
const strFunction = 'function';

/**
 * OLO: Source: https://github.com/text-mask/text-mask/blob/master/core/src/utilities.js
 */
function convertMaskToPlaceholder(mask = [], placeholderChar = defaultPlaceholderChar) {
  if (!isArray(mask)) {
    throw new Error('Text-mask:convertMaskToPlaceholder; The mask property must be an array.');
  }

  if (mask.indexOf(placeholderChar) !== -1) {
    throw new Error(
      'Placeholder character must not be used as part of the mask. Please specify a character ' +
        'that is not present in your mask as your placeholder character.\n\n' +
        `The placeholder character that was received is: ${JSON.stringify(placeholderChar)}\n\n` +
        `The mask that was received is: ${JSON.stringify(mask)}`
    );
  }

  return mask.map(char => (char instanceof RegExp ? placeholderChar : char)).join('');
}

function isArray(value) {
  return (Array.isArray && Array.isArray(value)) || value instanceof Array;
}

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

function isNumber(value) {
  return typeof value === 'number' && value.length === undefined && !isNaN(value);
}

const strCaretTrap = '[]';
function processCaretTraps(mask) {
  const indexes = [];

  let indexOfCaretTrap;
  // eslint-disable-next-line no-cond-assign
  while (((indexOfCaretTrap = mask.indexOf(strCaretTrap)), indexOfCaretTrap !== -1)) {
    // eslint-disable-line
    indexes.push(indexOfCaretTrap);

    mask.splice(indexOfCaretTrap, 1);
  }

  return { maskWithoutCaretTraps: mask, indexes };
}

/**
 * OLO: Source: https://github.com/text-mask/text-mask/blob/master/core/src/adjustCaretPosition.js
 */
// eslint-disable-next-line consistent-return
function adjustCaretPosition({
  previousConformedValue = '',
  previousPlaceholder = '',
  currentCaretPosition = 0,
  conformedValue,
  rawValue,
  placeholderChar,
  placeholder,
  indexesOfPipedChars = [],
  caretTrapIndexes = [],
}) {
  if (currentCaretPosition === 0 || !rawValue.length) {
    return 0;
  }

  // Store lengths for faster performance?
  const rawValueLength = rawValue.length;
  const previousConformedValueLength = previousConformedValue.length;
  const placeholderLength = placeholder.length;
  const conformedValueLength = conformedValue.length;

  // This tells us how long the edit is. If user modified input from `(2__)` to `(243__)`,
  // we know the user in this instance pasted two characters
  const editLength = rawValueLength - previousConformedValueLength;

  // If the edit length is positive, that means the user is adding characters, not deleting.
  const isAddition = editLength > 0;

  // This is the first raw value the user entered that needs to be conformed to mask
  const isFirstRawValue = previousConformedValueLength === 0;

  // A partial multi-character edit happens when the user makes a partial selection in their
  // input and edits that selection. That is going from `(123) 432-4348` to `() 432-4348` by
  // selecting the first 3 digits and pressing backspace.
  //
  // Such cases can also happen when the user presses the backspace while holding down the ALT
  // key.
  const isPartialMultiCharEdit = editLength > 1 && !isAddition && !isFirstRawValue;

  // This algorithm doesn't support all cases of multi-character edits, so we just return
  // the current caret position.
  //
  // This works fine for most cases.
  if (isPartialMultiCharEdit) {
    return currentCaretPosition;
  }

  // For a mask like (111), if the `previousConformedValue` is (1__) and user attempts to enter
  // `f` so the `rawValue` becomes (1f__), the new `conformedValue` would be (1__), which is the
  // same as the original `previousConformedValue`. We handle this case differently for caret
  // positioning.
  const possiblyHasRejectedChar =
    isAddition && (previousConformedValue === conformedValue || conformedValue === placeholder);

  let startingSearchIndex = 0;
  let trackRightCharacter;
  let targetChar;

  if (possiblyHasRejectedChar) {
    startingSearchIndex = currentCaretPosition - editLength;
  } else {
    // At this point in the algorithm, we want to know where the caret is right before the raw input
    // has been conformed, and then see if we can find that same spot in the conformed input.
    //
    // We do that by seeing what character lies immediately before the caret, and then look for that
    // same character in the conformed input and place the caret there.

    // First, we need to normalize the inputs so that letter capitalization between raw input and
    // conformed input wouldn't matter.
    const normalizedConformedValue = conformedValue.toLowerCase();
    const normalizedRawValue = rawValue.toLowerCase();

    // Then we take all characters that come before where the caret currently is.
    const leftHalfChars = normalizedRawValue.substr(0, currentCaretPosition).split('');

    // Now we find all the characters in the left half that exist in the conformed input
    // This step ensures that we don't look for a character that was filtered out or rejected by `conformToMask`.
    const intersection = leftHalfChars.filter(
      char => normalizedConformedValue.indexOf(char) !== -1
    );

    // The last character in the intersection is the character we want to look for in the conformed
    // value and the one we want to adjust the caret close to
    targetChar = intersection[intersection.length - 1];

    // Calculate the number of mask characters in the previous placeholder
    // from the start of the string up to the place where the caret is
    const previousLeftMaskChars = previousPlaceholder
      .substr(0, intersection.length)
      .split('')
      .filter(char => char !== placeholderChar).length;

    // Calculate the number of mask characters in the current placeholder
    // from the start of the string up to the place where the caret is
    const leftMaskChars = placeholder
      .substr(0, intersection.length)
      .split('')
      .filter(char => char !== placeholderChar).length;

    // Has the number of mask characters up to the caret changed?
    const masklengthChanged = leftMaskChars !== previousLeftMaskChars;

    // Detect if `targetChar` is a mask character and has moved to the left
    const targetIsMaskMovingLeft =
      previousPlaceholder[intersection.length - 1] !== undefined &&
      placeholder[intersection.length - 2] !== undefined &&
      previousPlaceholder[intersection.length - 1] !== placeholderChar &&
      previousPlaceholder[intersection.length - 1] !== placeholder[intersection.length - 1] &&
      previousPlaceholder[intersection.length - 1] === placeholder[intersection.length - 2];

    // If deleting and the `targetChar` `is a mask character and `masklengthChanged` is true
    // or the mask is moving to the left, we can't use the selected `targetChar` any longer
    // if we are not at the end of the string.
    // In this case, change tracking strategy and track the character to the right of the caret.
    if (
      !isAddition &&
      (masklengthChanged || targetIsMaskMovingLeft) &&
      previousLeftMaskChars > 0 &&
      placeholder.indexOf(targetChar) > -1 &&
      rawValue[currentCaretPosition] !== undefined
    ) {
      trackRightCharacter = true;
      targetChar = rawValue[currentCaretPosition];
    }

    // It is possible that `targetChar` will appear multiple times in the conformed value.
    // We need to know not to select a character that looks like our target character from the placeholder or
    // the piped characters, so we inspect the piped characters and the placeholder to see if they contain
    // characters that match our target character.

    // If the `conformedValue` got piped, we need to know which characters were piped in so that when we look for
    // our `targetChar`, we don't select a piped char by mistake
    const pipedChars = indexesOfPipedChars.map(index => normalizedConformedValue[index]);

    // We need to know how many times the `targetChar` occurs in the piped characters.
    const countTargetCharInPipedChars = pipedChars.filter(char => char === targetChar).length;

    // We need to know how many times it occurs in the intersection
    const countTargetCharInIntersection = intersection.filter(char => char === targetChar).length;

    // We need to know if the placeholder contains characters that look like
    // our `targetChar`, so we don't select one of those by mistake.
    const countTargetCharInPlaceholder = placeholder
      .substr(0, placeholder.indexOf(placeholderChar))
      .split('')
      .filter(
        (char, index) =>
          // Check if `char` is the same as our `targetChar`, so we account for it
          char === targetChar &&
          // but also make sure that both the `rawValue` and placeholder don't have the same character at the same
          // index because if they are equal, that means we are already counting those characters in
          // `countTargetCharInIntersection`
          rawValue[index] !== char
      ).length;

    // The number of times we need to see occurrences of the `targetChar` before we know it is the one we're looking
    // for is:
    const requiredNumberOfMatches =
      countTargetCharInPlaceholder +
      countTargetCharInIntersection +
      countTargetCharInPipedChars +
      // The character to the right of the caret isn't included in `intersection`
      // so add one if we are tracking the character to the right
      (trackRightCharacter ? 1 : 0);

    // Now we start looking for the location of the `targetChar`.
    // We keep looping forward and store the index in every iteration. Once we have encountered
    // enough occurrences of the target character, we break out of the loop
    // If are searching for the second `1` in `1214`, `startingSearchIndex` will point at `4`.
    let numberOfEncounteredMatches = 0;
    for (let i = 0; i < conformedValueLength; i++) {
      const conformedValueChar = normalizedConformedValue[i];

      startingSearchIndex = i + 1;

      if (conformedValueChar === targetChar) {
        numberOfEncounteredMatches++;
      }

      if (numberOfEncounteredMatches >= requiredNumberOfMatches) {
        break;
      }
    }
  }

  // At this point, if we simply return `startingSearchIndex` as the adjusted caret position,
  // most cases would be handled. However, we want to fast forward or rewind the caret to the
  // closest placeholder character if it happens to be in a non-editable spot. That's what the next
  // logic is for.

  // In case of addition, we fast forward.
  if (isAddition) {
    // We want to remember the last placeholder character encountered so that if the mask
    // contains more characters after the last placeholder character, we don't forward the caret
    // that far to the right. Instead, we stop it at the last encountered placeholder character.
    let lastPlaceholderChar = startingSearchIndex;

    for (let i = startingSearchIndex; i <= placeholderLength; i++) {
      if (placeholder[i] === placeholderChar) {
        lastPlaceholderChar = i;
      }

      if (
        // If we're adding, we can position the caret at the next placeholder character.
        placeholder[i] === placeholderChar ||
        // If a caret trap was set by a mask function, we need to stop at the trap.
        caretTrapIndexes.indexOf(i) !== -1 ||
        // This is the end of the placeholder. We cannot move any further. Let's put the caret there.
        i === placeholderLength
      ) {
        return lastPlaceholderChar;
      }
    }
  } else {
    // In case of deletion, we rewind.
    if (trackRightCharacter) {
      // Searching for the character that was to the right of the caret
      // We start at `startingSearchIndex` - 1 because it includes one character extra to the right
      for (let i = startingSearchIndex - 1; i >= 0; i--) {
        // If tracking the character to the right of the cursor, we move to the left until
        // we found the character and then place the caret right before it

        if (
          // `targetChar` should be in `conformedValue`, since it was in `rawValue`, just
          // to the right of the caret
          conformedValue[i] === targetChar ||
          // If a caret trap was set by a mask function, we need to stop at the trap.
          caretTrapIndexes.indexOf(i) !== -1 ||
          // This is the beginning of the placeholder. We cannot move any further.
          // Let's put the caret there.
          i === 0
        ) {
          return i;
        }
      }
    } else {
      // Searching for the first placeholder or caret trap to the left

      for (let i = startingSearchIndex; i >= 0; i--) {
        // If we're deleting, we stop the caret right before the placeholder character.
        // For example, for mask `(111) 11`, current conformed input `(456) 86`. If user
        // modifies input to `(456 86`. That is, they deleted the `)`, we place the caret
        // right after the first `6`

        if (
          // If we're deleting, we can position the caret right before the placeholder character
          placeholder[i - 1] === placeholderChar ||
          // If a caret trap was set by a mask function, we need to stop at the trap.
          caretTrapIndexes.indexOf(i) !== -1 ||
          // This is the beginning of the placeholder. We cannot move any further.
          // Let's put the caret there.
          i === 0
        ) {
          return i;
        }
      }
    }
  }
}

/**
 * OLO: Source: https://github.com/text-mask/text-mask/blob/master/core/src/conformToMask.js
 */
function conformToMask(rawValue = '', mask = [], config = {}) {
  if (!isArray(mask)) {
    // If someone passes a function as the mask property, we should call the
    // function to get the mask array - Normally this is handled by the
    // `createTextMaskInputElement:update` function - this allows mask functions
    // to be used directly with `conformToMask`
    if (typeof mask === strFunction) {
      // call the mask function to get the mask array
      mask = mask(rawValue, config);

      // mask functions can setup caret traps to have some control over how the caret moves. We need to process
      // the mask for any caret traps. `processCaretTraps` will remove the caret traps from the mask
      mask = processCaretTraps(mask).maskWithoutCaretTraps;
    } else {
      throw new Error('Text-mask:conformToMask; The mask property must be an array.');
    }
  }

  // These configurations tell us how to conform the mask
  const {
    guide = true,
    previousConformedValue = '',
    placeholderChar = defaultPlaceholderChar,
    placeholder = convertMaskToPlaceholder(mask, placeholderChar),
    currentCaretPosition,
    keepCharPositions,
  } = config;

  // The configs below indicate that the user wants the algorithm to work in *no guide* mode
  const suppressGuide = guide === false && previousConformedValue !== undefined;

  // Calculate lengths once for performance
  const rawValueLength = rawValue.length;
  const previousConformedValueLength = previousConformedValue.length;
  const placeholderLength = placeholder.length;
  const maskLength = mask.length;

  // This tells us the number of edited characters and the direction in which they were edited (+/-)
  const editDistance = rawValueLength - previousConformedValueLength;

  // In *no guide* mode, we need to know if the user is trying to add a character or not
  const isAddition = editDistance > 0;

  // Tells us the index of the first change. For (438) 394-4938 to (38) 394-4938, that would be 1
  const indexOfFirstChange = currentCaretPosition + (isAddition ? -editDistance : 0);

  // We're also gonna need the index of last change, which we can derive as follows...
  const indexOfLastChange = indexOfFirstChange + Math.abs(editDistance);

  // If `conformToMask` is configured to keep character positions, that is, for mask 111, previous value
  // _2_ and raw value 3_2_, the new conformed value should be 32_, not 3_2 (default behavior). That's in the case of
  // addition. And in the case of deletion, previous value _23, raw value _3, the new conformed string should be
  // __3, not _3_ (default behavior)
  //
  // The next block of logic handles keeping character positions for the case of deletion. (Keeping
  // character positions for the case of addition is further down since it is handled differently.)
  // To do this, we want to compensate for all characters that were deleted
  if (keepCharPositions === true && !isAddition) {
    // We will be storing the new placeholder characters in this variable.
    let compensatingPlaceholderChars = '';

    // For every character that was deleted from a placeholder position, we add a placeholder char
    for (let i = indexOfFirstChange; i < indexOfLastChange; i++) {
      if (placeholder[i] === placeholderChar) {
        compensatingPlaceholderChars += placeholderChar;
      }
    }

    // Now we trick our algorithm by modifying the raw value to make it contain additional placeholder characters
    // That way when the we start laying the characters again on the mask, it will keep the non-deleted characters
    // in their positions.
    rawValue =
      rawValue.slice(0, indexOfFirstChange) +
      compensatingPlaceholderChars +
      rawValue.slice(indexOfFirstChange, rawValueLength);
  }

  // Convert `rawValue` string to an array, and mark characters based on whether they are newly added or have
  // existed in the previous conformed value. Identifying new and old characters is needed for `conformToMask`
  // to work if it is configured to keep character positions.
  const rawValueArr = rawValue
    .split('')
    .map((char, i) => ({ char, isNew: i >= indexOfFirstChange && i < indexOfLastChange }));

  // The loop below removes masking characters from user input. For example, for mask
  // `00 (111)`, the placeholder would be `00 (___)`. If user input is `00 (234)`, the loop below
  // would remove all characters but `234` from the `rawValueArr`. The rest of the algorithm
  // then would lay `234` on top of the available placeholder positions in the mask.
  for (let i = rawValueLength - 1; i >= 0; i--) {
    const { char } = rawValueArr[i];

    if (char !== placeholderChar) {
      const shouldOffset = i >= indexOfFirstChange && previousConformedValueLength === maskLength;

      if (char === placeholder[shouldOffset ? i - editDistance : i]) {
        rawValueArr.splice(i, 1);
      }
    }
  }

  // This is the variable that we will be filling with characters as we figure them out
  // in the algorithm below
  let conformedValue = '';
  let someCharsRejected = false;

  // Ok, so first we loop through the placeholder looking for placeholder characters to fill up.
  // eslint-disable-next-line no-labels
  placeholderLoop: for (let i = 0; i < placeholderLength; i++) {
    const charInPlaceholder = placeholder[i];

    // We see one. Let's find out what we can put in it.
    if (charInPlaceholder === placeholderChar) {
      // But before that, do we actually have any user characters that need a place?
      if (rawValueArr.length > 0) {
        // We will keep chipping away at user input until either we run out of characters
        // or we find at least one character that we can map.
        while (rawValueArr.length > 0) {
          // Let's retrieve the first user character in the queue of characters we have left
          const { char: rawValueChar, isNew } = rawValueArr.shift();

          // If the character we got from the user input is a placeholder character (which happens
          // regularly because user input could be something like (540) 90_-____, which includes
          // a bunch of `_` which are placeholder characters) and we are not in *no guide* mode,
          // then we map this placeholder character to the current spot in the placeholder
          if (rawValueChar === placeholderChar && suppressGuide !== true) {
            conformedValue += placeholderChar;

            // And we go to find the next placeholder character that needs filling
            // eslint-disable-next-line no-labels
            continue placeholderLoop;

            // Else if, the character we got from the user input is not a placeholder, let's see
            // if the current position in the mask can accept it.
          } else if (mask[i].test(rawValueChar)) {
            // we map the character differently based on whether we are keeping character positions or not.
            // If any of the conditions below are met, we simply map the raw value character to the
            // placeholder position.
            if (
              keepCharPositions !== true ||
              isNew === false ||
              previousConformedValue === '' ||
              guide === false ||
              !isAddition
            ) {
              conformedValue += rawValueChar;
            } else {
              // We enter this block of code if we are trying to keep character positions and none of the conditions
              // above is met. In this case, we need to see if there's an available spot for the raw value character
              // to be mapped to. If we couldn't find a spot, we will discard the character.
              //
              // For example, for mask `1111`, previous conformed value `_2__`, raw value `942_2__`. We can map the
              // `9`, to the first available placeholder position, but then, there are no more spots available for the
              // `4` and `2`. So, we discard them and end up with a conformed value of `92__`.
              const rawValueArrLength = rawValueArr.length;
              // eslint-disable-next-line no-null/no-null
              let indexOfNextAvailablePlaceholderChar = null;

              // Let's loop through the remaining raw value characters. We are looking for either a suitable spot, ie,
              // a placeholder character or a non-suitable spot, ie, a non-placeholder character that is not new.
              // If we see a suitable spot first, we store its position and exit the loop. If we see a non-suitable
              // spot first, we exit the loop and our `indexOfNextAvailablePlaceholderChar` will stay as `null`.
              for (let j = 0; j < rawValueArrLength; j++) {
                const charData = rawValueArr[j];

                if (charData.char !== placeholderChar && charData.isNew === false) {
                  break;
                }

                if (charData.char === placeholderChar) {
                  indexOfNextAvailablePlaceholderChar = j;
                  break;
                }
              }

              // If `indexOfNextAvailablePlaceholderChar` is not `null`, that means the character is not blocked.
              // We can map it. And to keep the character positions, we remove the placeholder character
              // from the remaining characters
              // eslint-disable-next-line no-null/no-null
              if (indexOfNextAvailablePlaceholderChar !== null) {
                conformedValue += rawValueChar;
                rawValueArr.splice(indexOfNextAvailablePlaceholderChar, 1);

                // If `indexOfNextAvailablePlaceholderChar` is `null`, that means the character is blocked. We have to
                // discard it.
              } else {
                i--;
              }
            }

            // Since we've mapped this placeholder position. We move on to the next one.
            // eslint-disable-next-line no-labels
            continue placeholderLoop;
          } else {
            someCharsRejected = true;
          }
        }
      }

      // We reach this point when we've mapped all the user input characters to placeholder
      // positions in the mask. In *guide* mode, we append the left over characters in the
      // placeholder to the `conformedString`, but in *no guide* mode, we don't wanna do that.
      //
      // That is, for mask `(111)` and user input `2`, we want to return `(2`, not `(2__)`.
      if (suppressGuide === false) {
        conformedValue += placeholder.substr(i, placeholderLength);
      }

      // And we break
      break;

      // Else, the charInPlaceholder is not a placeholderChar. That is, we cannot fill it
      // with user input. So we just map it to the final output
    } else {
      conformedValue += charInPlaceholder;
    }
  }

  // The following logic is needed to deal with the case of deletion in *no guide* mode.
  //
  // Consider the silly mask `(111) /// 1`. What if user tries to delete the last placeholder
  // position? Something like `(589) /// `. We want to conform that to `(589`. Not `(589) /// `.
  // That's why the logic below finds the last filled placeholder character, and removes everything
  // from that point on.
  if (suppressGuide && isAddition === false) {
    // eslint-disable-next-line no-null/no-null
    let indexOfLastFilledPlaceholderChar = null;

    // Find the last filled placeholder position and substring from there
    for (let i = 0; i < conformedValue.length; i++) {
      if (placeholder[i] === placeholderChar) {
        indexOfLastFilledPlaceholderChar = i;
      }
    }

    // eslint-disable-next-line no-null/no-null
    if (indexOfLastFilledPlaceholderChar !== null) {
      // We substring from the beginning until the position after the last filled placeholder char.
      conformedValue = conformedValue.substr(0, indexOfLastFilledPlaceholderChar + 1);
    } else {
      // If we couldn't find `indexOfLastFilledPlaceholderChar` that means the user deleted
      // the first character in the mask. So we return an empty string.
      conformedValue = '';
    }
  }

  return { conformedValue, meta: { someCharsRejected } };
}

/**
 * OLO: Source: https://github.com/text-mask/text-mask/blob/master/core/src/createTextMaskInputElement.js
 */
const strNone = 'none';
const strObject = 'object';
const isAndroid = typeof navigator !== 'undefined' && /Android/i.test(navigator.userAgent);
const defer = typeof requestAnimationFrame !== 'undefined' ? requestAnimationFrame : setTimeout;

function createTextMaskInputElement(config) {
  // Anything that we will need to keep between `update` calls, we will store in this `state` object.
  const state = { previousConformedValue: undefined, previousPlaceholder: undefined };

  return {
    state,

    // `update` is called by framework components whenever they want to update the `value` of the input element.
    // The caller can send a `rawValue` to be conformed and set on the input element. However, the default use-case
    // is for this to be read from the `inputElement` directly.
    update(
      rawValue,
      {
        inputElement,
        mask: providedMask,
        guide,
        pipe,
        placeholderChar = defaultPlaceholderChar,
        keepCharPositions = false,
        showMask = false,
      } = config
    ) {
      // if `rawValue` is `undefined`, read from the `inputElement`
      if (typeof rawValue === 'undefined') {
        rawValue = inputElement.value;
      }

      // If `rawValue` equals `state.previousConformedValue`, we don't need to change anything. So, we return.
      // This check is here to handle controlled framework components that repeat the `update` call on every render.
      if (rawValue === state.previousConformedValue) {
        return;
      }

      // Text Mask accepts masks that are a combination of a `mask` and a `pipe` that work together. If such a `mask` is
      // passed, we destructure it below, so the rest of the code can work normally as if a separate `mask` and a `pipe`
      // were passed.
      if (
        typeof providedMask === strObject &&
        providedMask.pipe !== undefined &&
        providedMask.mask !== undefined
      ) {
        pipe = providedMask.pipe;
        providedMask = providedMask.mask;
      }

      // The `placeholder` is an essential piece of how Text Mask works. For a mask like `(111)`, the placeholder would
      // be `(___)` if the `placeholderChar` is set to `_`.
      let placeholder;

      // We don't know what the mask would be yet. If it is an array, we take it as is, but if it's a function, we will
      // have to call that function to get the mask array.
      let mask;

      // If the provided mask is an array, we can call `convertMaskToPlaceholder` here once and we'll always have the
      // correct `placeholder`.
      if (providedMask instanceof Array) {
        placeholder = convertMaskToPlaceholder(providedMask, placeholderChar);
      }

      // In framework components that support reactivity, it's possible to turn off masking by passing
      // `false` for `mask` after initialization. See https://github.com/text-mask/text-mask/pull/359
      if (providedMask === false) {
        return;
      }

      // We check the provided `rawValue` before moving further.
      // If it's something we can't work with `getSafeRawValue` will throw.
      const safeRawValue = getSafeRawValue(rawValue);

      // `selectionEnd` indicates to us where the caret position is after the user has typed into the input
      const { selectionEnd: currentCaretPosition } = inputElement;

      // We need to know what the `previousConformedValue` and `previousPlaceholder` is from the previous `update` call
      const { previousConformedValue, previousPlaceholder } = state;

      let caretTrapIndexes;

      // If the `providedMask` is a function. We need to call it at every `update` to get the `mask` array.
      // Then we also need to get the `placeholder`
      if (typeof providedMask === strFunction) {
        mask = providedMask(safeRawValue, {
          currentCaretPosition,
          previousConformedValue,
          placeholderChar,
        });

        // disable masking if `mask` is `false`
        if (mask === false) {
          return;
        }

        // mask functions can setup caret traps to have some control over how the caret moves. We need to process
        // the mask for any caret traps. `processCaretTraps` will remove the caret traps from the mask and return
        // the indexes of the caret traps.
        const { maskWithoutCaretTraps, indexes } = processCaretTraps(mask);

        mask = maskWithoutCaretTraps; // The processed mask is what we're interested in
        caretTrapIndexes = indexes; // And we need to store these indexes because they're needed by `adjustCaretPosition`

        placeholder = convertMaskToPlaceholder(mask, placeholderChar);

        // If the `providedMask` is not a function, we just use it as-is.
      } else {
        mask = providedMask;
      }

      // The following object will be passed to `conformToMask` to determine how the `rawValue` will be conformed
      const conformToMaskConfig = {
        previousConformedValue,
        guide,
        placeholderChar,
        pipe,
        placeholder,
        currentCaretPosition,
        keepCharPositions,
      };

      // `conformToMask` returns `conformedValue` as part of an object for future API flexibility
      const { conformedValue } = conformToMask(safeRawValue, mask, conformToMaskConfig);

      // The following few lines are to support the `pipe` feature.
      const piped = typeof pipe === strFunction;

      let pipeResults = {};

      // If `pipe` is a function, we call it.
      if (piped) {
        // `pipe` receives the `conformedValue` and the configurations with which `conformToMask` was called.
        pipeResults = pipe(conformedValue, { rawValue: safeRawValue, ...conformToMaskConfig });

        // `pipeResults` should be an object. But as a convenience, we allow the pipe author to just return `false` to
        // indicate rejection. Or return just a string when there are no piped characters.
        // If the `pipe` returns `false` or a string, the block below turns it into an object that the rest
        // of the code can work with.
        if (pipeResults === false) {
          // If the `pipe` rejects `conformedValue`, we use the `previousConformedValue`, and set `rejected` to `true`.
          pipeResults = { value: previousConformedValue, rejected: true };
        } else if (isString(pipeResults)) {
          pipeResults = { value: pipeResults };
        }
      }

      // Before we proceed, we need to know which conformed value to use, the one returned by the pipe or the one
      // returned by `conformToMask`.
      const finalConformedValue = piped ? pipeResults.value : conformedValue;

      // After determining the conformed value, we will need to know where to set
      // the caret position. `adjustCaretPosition` will tell us.
      const adjustedCaretPosition = adjustCaretPosition({
        previousConformedValue,
        previousPlaceholder,
        conformedValue: finalConformedValue,
        placeholder,
        rawValue: safeRawValue,
        currentCaretPosition,
        placeholderChar,
        indexesOfPipedChars: pipeResults.indexesOfPipedChars,
        caretTrapIndexes,
      });

      // Text Mask sets the input value to an empty string when the condition below is set. It provides a better UX.
      const inputValueShouldBeEmpty =
        finalConformedValue === placeholder && adjustedCaretPosition === 0;
      const emptyValue = showMask ? placeholder : '';
      const inputElementValue = inputValueShouldBeEmpty ? emptyValue : finalConformedValue;

      state.previousConformedValue = inputElementValue; // store value for access for next time
      state.previousPlaceholder = placeholder;

      // In some cases, this `update` method will be repeatedly called with a raw value that has already been conformed
      // and set to `inputElement.value`. The below check guards against needlessly readjusting the input state.
      // See https://github.com/text-mask/text-mask/issues/231
      if (inputElement.value === inputElementValue) {
        return;
      }

      inputElement.value = inputElementValue; // set the input value
      safeSetSelection(inputElement, adjustedCaretPosition); // adjust caret position
    },
  };
}

function safeSetSelection(element, selectionPosition) {
  if (document.activeElement === element) {
    if (isAndroid) {
      defer(() => element.setSelectionRange(selectionPosition, selectionPosition, strNone), 0);
    } else {
      element.setSelectionRange(selectionPosition, selectionPosition, strNone);
    }
  }
}

function getSafeRawValue(inputValue) {
  if (isString(inputValue)) {
    return inputValue;
  } else if (isNumber(inputValue)) {
    return String(inputValue);
    // eslint-disable-next-line no-null/no-null
  } else if (inputValue === undefined || inputValue === null) {
    return '';
  }
  throw new Error(
    "The 'value' provided to Text Mask needs to be a string or a number. The value " +
      `received was:\n\n ${JSON.stringify(inputValue)}`
  );
}

/**
 * OLO: Source: https://github.com/text-mask/text-mask/blob/master/ember/addon/components/masked-input.js
 */

function _config(...args) {
  return computed(...args, function () {
    return getProperties(this, ...args);
  });
}

const mask = [];

/*
  ## MaskedInputComponent
  Add the following markup to your template to render a masked input component.
  ```hbs
  {{masked-input mask=mask}}
  ```
  In the template's controller, specify a `mask`.
  ```js
  import Ember from 'ember';
  export default Ember.Controller.extend({
    mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  });
  ```
*/
export default TextField.extend({
  mask,

  /*
    ## config {Object}
    This is a computed property and will re-compute when any of the dependent properties
    update.  By default it will read the properties off the component root, you
    can pass in attrbutes to the component through the template.
    ```hbs
    {{masked-input
      mask=customMask
      guide=true}}
    ```
  */
  config: _config('mask', 'guide', 'placeholderChar', 'keepCharPositions', 'pipe', 'showMask'),

  /*
    ## textMaskInputElement {Object}
    `textMaskInputElement` is the object that is returned from calling the
    `createTextMaskInputElement`. method.
    This is a computed property and will re-compute whenever the `config` property
    changes.
  */
  textMaskInputElement: computed('config', 'element', function () {
    const config = this.config;
    config.inputElement = this.element;
    return this.createTextMaskInputElement(config);
  }),

  createTextMaskInputElement,

  update() {
    /**
     * OLO: This is what the library was originally doing:
     *
     *     this.textMaskInputElement.update(...arguments);
     *
     * But we have to do it in an afterRender for reasons I forget.
     * It had something to do with phone masking:
     * https://github.com/ololabs/mobile-web-client/commit/865023c41530524d4475ebdefd16010a901a8c77
     */
    schedule('afterRender', () => {
      this.textMaskInputElement.update(...arguments);
    });
  },

  _input: on('input', 'didRender', function () {
    this.update();
  }),
});
