/* eslint-disable */
// Based on form-detail/input

// FIXME: replace this entire component with ember-component-attributes

import Component from '@ember/component';
import { computed } from '@ember/object';
import { schedule } from '@ember/runloop';

import { isFunction } from 'mobile-web/lib/utilities/_';
import { Attribute, Autocomplete, HtmlBool, InputMode } from 'mobile-web/lib/utilities/html-types';
import isSome from 'mobile-web/lib/utilities/is-some';

// TODO: remove eslint-disabling by reworking as class with optional types
/* eslint-disable no-null/no-null */
export default class FormFieldInput extends Component.extend({
  // Passed in
  tagName: 'input' as string,
  autocapitalize: 'on' as string,
  autocomplete: undefined as Autocomplete,
  autofocus: undefined as HtmlBool,
  aria: null as object | null,
  inputValue: null as any,
  inputMode: null as InputMode | null,
  class: '',
  name: '',
  min: null as Attribute<number> | null,
  max: null as Attribute<number> | null,
  size: undefined,
  readonly: undefined as HtmlBool,
  required: undefined as HtmlBool,
  disabled: undefined as HtmlBool,
  checked: undefined as HtmlBool,
  tabindex: null as Attribute<number> | null,
  type: 'text',
  pattern: undefined as any | string,
  placeholder: null as Attribute<string> | null,
  novalidate: undefined as HtmlBool,
  invalid: undefined as HtmlBool,
  hasValidationMessage: false,

  // This needs to return a string because `aria-invalid` requires value to be "true" or "false"
  _invalid: computed('invalid', function (): 'true' | 'false' {
    if (this.invalid) {
      return 'true';
    }
    return 'false';
  }),

  _required: computed('required', function (): 'true' | 'false' {
    if (this.required) {
      return 'true';
    }
    return 'false';
  }),

  classNameBindings: ['class'],
  attributeBindings: [
    '_invalid:aria-invalid',
    '_required:aria-required',
    'aria.describedby:aria-describedby',
    'autocapitalize',
    'autocomplete',
    'autofocus',
    'checked',
    'disabled',
    'inputMode:inputmode',
    'inputValue:value',
    'max',
    'maxlength',
    'min',
    'minlength',
    'name:data-test-form-field-input',
    'name:data-test-selector',
    'name',
    'novalidate',
    'pattern',
    'placeholder',
    'readonly',
    'size',
    'tabindex',
  ],

  init(this: FormFieldInput) {
    this._super(...arguments);

    const ariaAttrs = this.aria;
    const attributeBindings = this.attributeBindings;
    let extraAttrs: string[] = [];

    if (isSome(ariaAttrs)) {
      const ariaBindings = ariaAttrs ? Object.keys(ariaAttrs).map(a => `aria.${a}:aria-${a}`) : [];
      extraAttrs = extraAttrs.concat([], ariaBindings);
    }

    if (this.tagName === 'input') {
      extraAttrs.push('type');
    }

    this.set('attributeBindings', attributeBindings.concat(extraAttrs));
  },

  change(this: FormFieldInput, e: Event) {
    schedule('render', this, this.fixValue);

    const value = (e.target as EventTarget & HTMLInputElement).value;
    this.set('inputValue', value);
    this.onChange(value);
  },

  focusIn(this: FormFieldInput, _e: Event) {
    if (isFunction(this.onFocus)) {
      this.onFocus();
    }
  },

  input(this: FormFieldInput, e: Event) {
    schedule('render', this, this.fixValue);

    const previousValue = this.inputValue;
    const value = (e.target as EventTarget & HTMLInputElement).value;

    if (this.pattern && value) {
      const reg = new RegExp(this.pattern);
      if (!reg.test(value)) {
        return;
      }
    }

    this.set('inputValue', value);
    const onInput = this.onInput;
    if (isFunction(onInput)) {
      onInput(value, previousValue);
    }
  },
}) {
  onChange!: (value: any) => void;
  onFocus?: () => void;
  onInput?: (value: any, previousValue: any) => void;

  fixValue() {
    const input = this.element;

    if (
      (input instanceof HTMLInputElement || input instanceof HTMLTextAreaElement) &&
      input.value !== this.inputValue
    ) {
      input.value = this.inputValue;
    }
  }
}
