import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import Order from 'mobile-web/models/order';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import BootstrapService from 'mobile-web/services/bootstrap';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

interface Args {
  // Required arguments
  order: Order;
  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class PostCheckoutTotals extends Component<Signature> {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service storage!: StorageService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get feesTotal(): number {
    const fees = this.args.order.fees.map(fee => fee.amount ?? 0);
    return fees.reduce((acc, cur) => acc + cur) + this.args.order.vendorTax;
  }

  get isOloAuth(): boolean {
    return (
      !!this.bootstrap.data?.isOloAuthLogin || !!this.storage.orderSubmission?.createOloAccount
    );
  }

  get isTaxExempt() {
    return this.args.order && this.args.order.isTaxExempt;
  }

  get taxExemptId() {
    return this.args.order && this.args.order.taxExemptAccountId;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  trackClick() {
    this.analytics.trackEvent(AnalyticsEvents.OloAuthLearnMoreLinkClicked, undefined, {
      bucket: 'all',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::Totals': typeof PostCheckoutTotals;
  }
}
