// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import DS from 'ember-data';

import UpsellGroupModel from 'mobile-web/models/upsell-group';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';

export function trackViewUpsellEvent(
  upsellGroups: UpsellGroupModel[],
  store: DS.Store,
  analytics: AnalyticsService,
  smartUpsellsDisplayed = false
): void {
  const upsellItems = upsellGroups.flatMap(g => g.items.map(i => i));
  const uniqueCategories = [
    ...new Set(upsellItems.map(item => store.peekRecord('product', item.id)?.category?.name)),
  ];
  const modelVersion = upsellGroups.reduce((prev, ug) => prev || ug?.modelVersion, '');
  const recentProductCount =
    upsellGroups.reduce((prev, ug) => prev ?? ug?.recentProductCount, undefined) ?? 0;

  analytics.trackEvent(AnalyticsEvents.ViewUpsellsAction, () => ({
    [AnalyticsProperties.SmartUpsellsDisplayed]: smartUpsellsDisplayed,
    [AnalyticsProperties.UpsellReturnedCount]: upsellItems.length,
    [AnalyticsProperties.UpsellCategories]: uniqueCategories,
    [AnalyticsProperties.UpsellProductIds]: upsellItems.map(x => x.id),
    [AnalyticsProperties.RecommendationModelVersion]: modelVersion,
    [AnalyticsProperties.RecommendationRecentProductCount]: recentProductCount,
  }));
}
