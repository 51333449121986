import DS from 'ember-data';

export default class TaxExemptAccount extends DS.Model {
  @DS.attr('string')
  identifier!: string;
  @DS.attr('string')
  state!: string;
  @DS.attr('date')
  certificateExpirationDate?: Date;
}
