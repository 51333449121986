export default {
  "root": "_root_1u8ugx",
  "isLandscape": "_isLandscape_1u8ugx",
  "hasImage": "_hasImage_1u8ugx",
  "menu": "_menu_1u8ugx",
  "isDisabled": "_isDisabled_1u8ugx",
  "row": "_row_1u8ugx",
  "image": "_image_1u8ugx",
  "page": "_page_1u8ugx",
  "modal": "_modal_1u8ugx",
  "list": "_list_1u8ugx",
  "featured": "_featured_1u8ugx",
  "mostOrdered": "_mostOrdered_1u8ugx",
  "quickAdd": "_quickAdd_1u8ugx",
  "animatedContainer": "_animatedContainer_1u8ugx",
  "quickAddIconContainer": "_quickAddIconContainer_1u8ugx",
  "quickAddIcon": "_quickAddIcon_1u8ugx",
  "doneIcon": "_doneIcon_1u8ugx _quickAddIcon_1u8ugx",
  "productInfo": "_productInfo_1u8ugx",
  "hasFeatured": "_hasFeatured_1u8ugx",
  "name": "_name_1u8ugx",
  "hasQuickAdd": "_hasQuickAdd_1u8ugx",
  "productButton": "_productButton_1u8ugx",
  "priceLine": "_priceLine_1u8ugx",
  "hasOverride": "_hasOverride_1u8ugx",
  "overrideLabel": "_overrideLabel_1u8ugx",
  "price": "_price_1u8ugx",
  "calories": "_calories_1u8ugx",
  "hasPrice": "_hasPrice_1u8ugx",
  "description": "_description_1u8ugx _row_1u8ugx",
  "fullDescription": "_fullDescription_1u8ugx",
  "availability": "_availability_1u8ugx _row_1u8ugx",
  "icon": "_icon_1u8ugx",
  "isDisabledBanner": "_isDisabledBanner_1u8ugx _row_1u8ugx",
  "isDisabledDescription": "_isDisabledDescription_1u8ugx"
};
