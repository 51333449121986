export default {
  "formFields": "_formFields_7h8o5w",
  "formField": "_formField_7h8o5w",
  "checkboxRow": "_checkboxRow_7h8o5w",
  "checkboxError": "_checkboxError_7h8o5w",
  "submitButton": "_submitButton_7h8o5w",
  "heading": "_heading_7h8o5w",
  "errorMessage": "_errorMessage_7h8o5w",
  "errorIcon": "_errorIcon_7h8o5w",
  "errorMessageText": "_errorMessageText_7h8o5w"
};
