import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: []
  }
}

export default class RequiredMessage extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RequiredMessage': typeof RequiredMessage;
  }
}
