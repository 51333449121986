import { action } from '@ember/object';
import Component from '@glimmer/component';

import { POPUP_OPTIONS } from 'mobile-web/lib/popup';
import { ContentString } from 'mobile-web/services/content';

import style from './index.m.scss';

const X_SHARE_URL = 'https://x.com/intent/post';

interface Args {
  // Required arguments
  link: string;

  // Optional arguments
  quote?: ContentString;
}

interface Signature {
  Element: HTMLButtonElement;

  Args: Args;
}

export default class XShareButton extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get quote(): string {
    return this.args.quote?.toString() ?? '';
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  share() {
    const link = this.args.link;
    const quote = encodeURIComponent(this.quote);
    const url = `${X_SHARE_URL}?url=${link}&text=${quote}`;

    window.open(url, 'x', POPUP_OPTIONS);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    XShareButton: typeof XShareButton;
  }
}
