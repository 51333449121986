import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

import isSome from 'mobile-web/lib/utilities/is-some';
import { Address } from 'mobile-web/models/vendor';
import FeaturesService from 'mobile-web/services/features';
import VendorService from 'mobile-web/services/vendor';

export function uniqueCategoryId(categoryId: EmberDataId, vendorId: EmberDataId): string {
  return `${categoryId}_${vendorId}`;
}

export default class VendorSerializer extends DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin) {
  @service features!: FeaturesService;
  @service vendor!: VendorService;
  attrs = {
    embeddedProductGroups: { embedded: 'always' },
  };

  normalizeResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: {
      vendor: {
        id: EmberDataId;
        categories: EmberDataId[];
      };
      categories?: { id: EmberDataId }[];
      recentItems?: { id: EmberDataId; isRecentItem: boolean; products: EmberDataId[] };
      mostOrdered?: {
        id: EmberDataId;
        isMostOrdered: boolean;
        products: EmberDataId[];
        name: string;
      };
      products?: {
        id: EmberDataId;
        category: EmberDataId;
        recentItemCategory: EmberDataId;
        recentItemSortOrder: number;
        mostOrderedCategory: EmberDataId;
        mostOrderedRank: number;
      }[];
    },
    id: EmberDataId,
    requestType: string
  ): UnknownObject {
    const vendorId = payload.vendor.id;
    payload.vendor.categories = payload.vendor.categories.map(cId =>
      uniqueCategoryId(cId, vendorId)
    );
    payload.categories?.forEach(c => {
      c.id = uniqueCategoryId(c.id, vendorId);
    });

    if (typeof payload.recentItems !== 'undefined') {
      let index = 0;
      payload.recentItems!.id = uniqueCategoryId('recent-items-id', vendorId);
      payload.recentItems!.isRecentItem = true;
      payload.recentItems!.products.forEach(pId =>
        payload.products?.forEach(p => {
          if (pId === p.id) {
            p.recentItemCategory = payload.recentItems!.id;
            p.recentItemSortOrder = index++;
          }
        })
      );
      payload.categories?.addObject(payload.recentItems);
      payload.vendor.categories.addObject(payload.recentItems!.id);
      delete payload.recentItems;
    } else if (this.vendor.maxRecentItems !== undefined) {
      // put a placeholder object into the store to show we tried
      const placeHolder = {
        id: uniqueCategoryId('recent-items-id', vendorId),
        recentProducts: [],
        isRecentItem: true,
      };
      payload.categories?.addObject(placeHolder);
      payload.vendor.categories.addObject(placeHolder.id);
    }

    if (typeof payload.mostOrdered !== 'undefined') {
      let index = 0;
      payload.mostOrdered!.id = uniqueCategoryId('most-ordered-id', vendorId);
      payload.mostOrdered!.isMostOrdered = true;
      payload.mostOrdered!.name = 'Local Favorites';
      payload.mostOrdered!.products.forEach(pId =>
        payload.products?.forEach(p => {
          if (pId === p.id) {
            p.mostOrderedCategory = payload.mostOrdered!.id;
            p.mostOrderedRank = ++index;
          }
        })
      );
      payload.categories?.addObject(payload.mostOrdered);
      payload.vendor.categories.addObject(payload.mostOrdered!.id);
      delete payload.mostOrdered;
    } else if (this.vendor.maxMostOrdered > 0) {
      // put a placeholder object into the store to show we tried
      const placeHolder = {
        id: uniqueCategoryId('most-ordered-id', vendorId),
        mostOrdered: [],
        isMostOrdered: true,
      };
      payload.categories?.addObject(placeHolder);
      payload.vendor.categories.addObject(placeHolder.id);
    }

    payload.products?.forEach(p => {
      p.category = uniqueCategoryId(p.category, vendorId);
    });

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  normalize(
    modelClass: DS.Model,
    resourceHash: {
      address?: Address;
      embeddedProductGroups?: Array<{ items: Array<{ cost?: number }> }>;
      settings?: { acceptedCreditCardTypes: string };
    }
  ): UnknownObject {
    if (isSome(resourceHash.settings)) {
      // acceptedCreditCardTypes comes to us as a single comma separated string, like so:
      // 'Amex, Discover, Mastercard, Visa'
      // This logic splits it into an array and matches case to the enum in lib/payment/card.ts
      const acceptedCreditCardTypes = isEmpty(resourceHash.settings.acceptedCreditCardTypes)
        ? []
        : resourceHash.settings.acceptedCreditCardTypes.split(', ');
      const strCardTypes = acceptedCreditCardTypes.map(cardType => {
        if (cardType === 'Amex' || cardType === 'Visa') {
          return cardType.toUpperCase();
        }
        return cardType;
      });
      // We're purposefully transforming from a string to a string[]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resourceHash.settings.acceptedCreditCardTypes = strCardTypes as any;
    }

    if (isSome(resourceHash.embeddedProductGroups)) {
      resourceHash.embeddedProductGroups.forEach(upsellGroup => {
        upsellGroup.items.forEach(upsellItem => {
          delete upsellItem.cost;
        });
      });
    }

    if (resourceHash.address) {
      resourceHash.address.crossStreet = resourceHash.address.crossStreet?.trim();
    }

    return super.normalize(modelClass, resourceHash);
  }
}
