import { computed } from '@ember/object';
import DS from 'ember-data';

import { Provider } from 'mobile-web/lib/payment';

import BasketModel from './basket';
import BillingScheme from './billing-scheme';
import TaxExemptAccount from './tax-exempt-account';

// Hardcoded ID for dynamic model.
export const BILLING_DETAILS_ID = 1;

/**
 * This is a "dynamic model": a model that doesn't actually map to a persisted object.
 * It's used to represent a response from the API.
 *
 * This isn't technically the right use case for a model. Models should be objects
 * that are persisted in the backend. However, the `BasketModel` treats this as if
 * it were a persisted backend object; billing details live in a separate endpoint
 * from the parent basket model. We're making it work by assuming that there will
 * never be more than one `BillingDetails` in the store at any time, so the ID
 * doesn't matter.
 */
export default class BillingDetails extends DS.Model {
  @DS.attr('boolean', { defaultValue: false })
  allowCardsOnFile!: boolean;
  @DS.attr('boolean', { defaultValue: false })
  allowSplitPayments!: boolean;
  @DS.attr('boolean', { defaultValue: false })
  canPayCash!: boolean;
  @DS.attr('boolean', { defaultValue: false })
  doCreditCardStreetAddressValidation!: boolean;
  @DS.attr('boolean', { defaultValue: false })
  showTip!: boolean;

  @DS.belongsTo('basket')
  basket!: BasketModel;

  @DS.hasMany('billing-scheme', { async: false })
  billingSchemes!: DS.ManyArray<BillingScheme>;

  @computed('billingSchemes.[]', 'canPayCash')
  get filteredBillingSchemes(): BillingScheme[] {
    return this.billingSchemes.filter(scheme => {
      if (scheme.provider === Provider.PassThrough) {
        return this.canPayCash;
      } else if (scheme.provider === Provider.DigitalWallet) {
        return false;
      }

      return true;
    });
  }

  @DS.hasMany('tax-exempt-account', { async: false })
  taxExemptAccounts!: DS.ManyArray<TaxExemptAccount>;
}
