import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { PreviousRoute } from 'mobile-web/lib/routing';
import OrderSearchResultModel from 'mobile-web/models/order-search-result';
import AnalyticsService from 'mobile-web/services/analytics';
import BootstrapService from 'mobile-web/services/bootstrap';
import FeaturesService from 'mobile-web/services/features';
import NotificationsService from 'mobile-web/services/notifications';
import { Notification } from 'mobile-web/services/notifications';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

export default class IndexRoute extends Route {
  previousRoute?: PreviousRoute = undefined;

  @service features!: FeaturesService;
  @service notifications!: NotificationsService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service analytics!: AnalyticsService;
  @service bootstrap!: BootstrapService;

  model(): OrderSearchResultModel[] | PromiseLike<OrderSearchResultModel[]> {
    if (this.session.isLoggedIn) {
      return this.store
        .findAll('order-search-result')
        .then(orders => orders.filterBy('canUserCheckin', true));
    }

    return [];
  }

  afterModel() {
    const startupNotifications = this.storage.get('startupNotifications') ?? [];
    if (
      !Array.isArray(startupNotifications) ||
      !this.validStartupNotifications(startupNotifications)
    ) {
      return;
    }
    this.notifications.show(startupNotifications);
    this.storage.set('startupNotifications', []);
  }

  validStartupNotifications(notifications: Notification[]) {
    for (const notification of notifications) {
      const validNotificationObject =
        typeof notification === 'object' &&
        'level' in notification &&
        'message' in notification &&
        'type' in notification &&
        typeof notification.level === 'number' &&
        typeof notification.message === 'string' &&
        typeof notification.type === 'number';

      if (!validNotificationObject) {
        return validNotificationObject;
      }
    }

    return true;
  }
}
