import Service, { inject as service } from '@ember/service';

import { isDeliveryMode } from 'mobile-web/lib/order-criteria';

import BasketService from './basket';
import BootstrapService from './bootstrap';
import ChannelService from './channel';
import OnPremiseService, { ON_PREMISE_JUNK_PHONE_NUMBER } from './on-premise';
import SessionService from './session';

export enum DineInRequirePhoneNumber {
  HIDDEN = 0,
  OPTIONAL = 1,
  REQUIRED = 2,
}

export default class ContactService extends Service {
  // Service injections
  @service basket!: BasketService;
  @service bootstrap!: BootstrapService;
  @service channel!: ChannelService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;

  // Untracked properties

  // Tracked properties

  // Getters and setters - phone number
  get phoneNumberRequired(): boolean {
    // delivery always requires phone number
    if (this.basket.basket && isDeliveryMode(this.basket.basket?.handoffMode)) {
      return true;
    }
    // if borderless is enabled, always require phone number
    if (this.bootstrap.data?.hasOloAuthProvider) {
      return true;
    }

    if (this.isOnPrem) {
      if (this.session.isLoggedIn) {
        return (
          this.channelRequirePhone || this.dineInRequirePhone === DineInRequirePhoneNumber.REQUIRED
        );
      }
      return this.dineInRequirePhone === DineInRequirePhoneNumber.REQUIRED;
    }
    return this.channelRequirePhone;
  }

  get phoneNumberHiddenForDineIn(): boolean {
    return (
      this.isOnPrem &&
      this.dineInRequirePhone === DineInRequirePhoneNumber.HIDDEN &&
      !this.bootstrap.data?.hasOloAuthProvider
    );
  }

  get phoneNumberHiddenForOffPrem() {
    return this.channel.settings?.showPhoneNumberIfNotRequired;
  }

  // Getters and setters - email address
  get emailAddressIsVisible(): boolean {
    return this.onPremise.isPayAtTable || (this.isOnPrem && !this.session.user?.emailAddress);
  }

  get emailRequired(): boolean {
    //special case for P@T because that flow doesn't use Guest Checkout form for implicit account creation
    if (
      this.onPremise.isPayAtTable ||
      (this.isOnPrem && !this.bootstrap.data?.hasOloAuthProvider)
    ) {
      return this.dineInRequireEmail;
    }
    return true;
  }

  get lastNameRequired(): boolean {
    return !!this.bootstrap.data?.hasOloAuthProvider || !this.isOnPrem;
  }

  get dineInRequireEmail() {
    return this.channel.settings?.dineInRequireEmail ?? false;
  }
  // Private getters
  private get channelRequirePhone(): boolean {
    return !!this.channel.settings?.requirePhoneNumber;
  }
  private get dineInRequirePhone() {
    return this.channel.settings?.dineInRequirePhoneNumber;
  }
  private get isOnPrem(): boolean {
    return this.onPremise.isEnabled;
  }

  // Constructor

  // Other methods
  public getPhoneNumberDefault(customerEntry: string): string {
    if (this.phoneNumberRequired) return customerEntry;

    const returnPhoneDefault = this.channelRequirePhone ? ON_PREMISE_JUNK_PHONE_NUMBER : '';

    if (this.dineInRequirePhone === DineInRequirePhoneNumber.HIDDEN) {
      return returnPhoneDefault;
    } else if (this.dineInRequirePhone === DineInRequirePhoneNumber.OPTIONAL) {
      return customerEntry ? customerEntry : returnPhoneDefault;
    }
    return customerEntry;
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    contact: ContactService;
  }
}
