import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { getNameForLoginProvider } from 'mobile-web/lib/login-provider';
import { LoginProvider } from 'mobile-web/models/bootstrap-data';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BootstrapService from 'mobile-web/services/bootstrap';
import FeaturesService from 'mobile-web/services/features';
import LoyaltyService from 'mobile-web/services/loyalty';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

export default class LinkLoyaltyAccountBanner extends Component {
  // Untracked properties
  style = style;

  @tracked showOloAuthLoyaltyLinkBanner = this.setOloAuthLoyaltyLinkBanner();

  @service session!: SessionService;
  @service features!: FeaturesService;
  @service bootstrap!: BootstrapService;
  @service analytics!: AnalyticsService;
  @service loyalty!: LoyaltyService;

  get linkingCandidateProvider(): LoginProvider | undefined {
    const providers = this.session.loginProviders;

    return providers?.find(
      lp => lp.enableOloAuthLinking && lp.name !== this.session.loginProviderName
    );
  }

  get linkingCandidateName(): string {
    return getNameForLoginProvider(this.linkingCandidateProvider, '');
  }

  @action
  linkLoyaltyAccount() {
    if (this.linkingCandidateProvider) {
      this.analytics.trackEvent(
        AnalyticsEvents.LinkLoyaltyAccountClicked,
        () => ({ [AnalyticsProperties.LinkLoyaltyTarget]: this.linkingCandidateName }),
        {
          bucket: 'all',
        }
      );
      this.session.externalLoginWithNextRoute(this.linkingCandidateProvider.slug, true);
    }
  }

  @action
  setOloAuthLoyaltyLinkBanner() {
    const isEnabled = this.features.platformFlags['unlinked-user-info-olo-84530'];
    return (
      isEnabled &&
      this.session.isOloAuthLogin &&
      this.loyalty.peekMembershipCount === 0 &&
      this.session.linkedLoginProviders.length === 0 &&
      !!this.linkingCandidateProvider &&
      !this.session.unlinkedLoginProviders.find(
        lp => lp.slug === this.linkingCandidateProvider?.slug
      )
    );
  }

  @action
  dismissOloAuthLoyaltyLinkBanner() {
    this.showOloAuthLoyaltyLinkBanner = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::LinkLoyaltyAccountBanner': typeof LinkLoyaltyAccountBanner;
  }
}
