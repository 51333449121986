import DS from 'ember-data';

import memberAction, { MemberAction } from 'mobile-web/decorators/member-action';

import BasketModel, { pushBasketPayload } from './basket';

const { Model, attr } = DS;

export type TicketDiscount = {
  name: string;
  value: number;
};

export type TicketItemDiscount = {
  id: string;
  name: string;
  value: number;
};

export type TicketItem = {
  id: string;
  name: string;
  price: number;
  quantity: number;
  modifiers?: Array<TicketItem>;
  discounts?: Array<TicketItemDiscount>;
};

export type TicketTable = {
  id: string;
};

export type TicketTotals = {
  combos: number;
  discounts: number;
  due: number;
  items: number;
  otherCharges: number;
  paid: number;
  serviceCharges: number;
  subTotal: number;
  tax: number;
  tips: number;
  total: number;
};

export default class TicketModel extends Model {
  @attr discounts!: TicketDiscount[];
  @attr items!: TicketItem[];
  @attr oloPosReference!: string;
  @attr table?: TicketTable;
  @attr ticketNumber!: string;
  @attr totals!: TicketTotals;

  @memberAction({
    type: 'post',
    urlType: 'basketFromExistingTicket',
    after(this: TicketModel, response: AnyObject) {
      return pushBasketPayload.call(this, response, false);
    },
  })
  createBasket!: MemberAction<void, BasketModel>;
}
