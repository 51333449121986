import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import { task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import pick from 'lodash.pick';

import { GuestUser } from 'mobile-web/lib/customer';
import { LoginProvider } from 'mobile-web/models/bootstrap-data';
import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import ContactService from 'mobile-web/services/contact';
import ErrorService from 'mobile-web/services/error';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

interface Args {
  vendor: Vendor;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class GroupOrderStartRoute extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service error!: ErrorService;
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service groupOrder!: GroupOrderService;
  @service contact!: ContactService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get userData(): GuestUser | undefined {
    const user = this.session.currentUser;
    return user
      ? pick(user, 'firstName', 'lastName', 'emailAddress', 'contactNumber', 'optIn')
      : undefined;
  }

  get supportsGuestOrdering(): boolean {
    return this.channel.settings?.guestOrdering ?? false;
  }

  get guestCheckoutButtonText(): string {
    return this.showLoginOptions
      ? this.intl.t('mwc.guestCheckoutForm.proceedText')
      : this.intl.t('mwc.groupOrder.startButton');
  }

  get loginProviders(): LoginProvider[] {
    return this.channel.current?.loginProviders ?? [];
  }

  get supportsOloLogin(): boolean {
    return this.channel.settings?.supportsOloLogin ?? false;
  }

  get showLoginOptions(): boolean {
    return !this.session.isLoggedIn && (this.supportsOloLogin || this.loginProviders.length > 0);
  }

  // Lifecycle methods

  // Other methods

  // Tasks
  submitTask = task(this, async (user: GuestUser): Promise<void> => {
    if (this.session.currentUser && !this.session.isLoggedIn) {
      await this.session.logout();
    }
    if (!this.basket.basket) {
      await this.basket.createBasket(this.args.vendor);
    }
    if (!this.session.currentUser) {
      if (!this.onPremise.isEnabled && !user.emailAddress) {
        this.error.sendUserMessage({ detail: 'Email address required' });
        return;
      }

      this.onPremise.prepareGuestUser(user);

      const response = await this.store.collectionAction('user', 'guestGrouporderStart', user);

      await this.session.setUserFromPayload(response);
    }

    await this.groupOrder.startGroupOrder();

    this.analytics.trackEvent(AnalyticsEvents.GroupOrderStarted);
    this.groupOrder.openInviteModal = true;
    this.router.transitionTo('menu.vendor', this.args.vendor.slug);
  });

  // Actions and helpers
  @action
  externalLogin(provider: LoginProvider) {
    this.session.externalLoginWithNextRoute(provider.slug);
  }

  @action
  login() {
    this.session.transitionToLogin();
  }

  @action
  async startGroupOrder() {
    if (this.session.isLoggedIn) {
      await this.submitTask.perform(this.userData!);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::GroupOrder::StartRoute': typeof GroupOrderStartRoute;
  }
}
