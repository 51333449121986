import Controller from '@ember/controller';
import { action } from '@ember/object';
import Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { App, AppState, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { StatusBar } from '@capacitor/status-bar';
import IntlService from 'ember-intl/services/intl';
import { MediaService } from 'ember-responsive';

import { StaticContentType } from 'mobile-web/components/static-content-modal';
import { fetchOAuthCallback } from 'mobile-web/lib/hybrid-util';
import Keyboard from 'mobile-web/lib/plugins/keyboard';
import { PreviousRoute } from 'mobile-web/lib/routing';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import DeviceService, { LAST_BACKGROUNDED_KEY } from 'mobile-web/services/device';
import ErrorService from 'mobile-web/services/error';
import LoyaltyService from 'mobile-web/services/loyalty';
import SessionService from 'mobile-web/services/session';
import SideMenuService from 'mobile-web/services/side-menu';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';
import WindowService from 'mobile-web/services/window';
import style from 'mobile-web/styles/routes/application';

export enum RouteChangeFrom {
  BASKET_CONTENTS = 'basket-contents',
}

export default class ApplicationController extends Controller {
  // Service injections
  @service basket!: BasketService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service loyalty!: LoyaltyService;
  @service media!: MediaService;
  @service router!: Router;
  @service session!: SessionService;
  @service sideMenu!: SideMenuService;
  @service vendor!: VendorService;
  @service window!: WindowService;
  @service intl!: IntlService;
  @service error!: ErrorService;
  @service analytics!: AnalyticsService;
  @service storage!: StorageService;

  // Untracked properties
  backButtonListener?: PluginListenerHandle;
  keyboardWillShowListener?: PluginListenerHandle;
  keyboardWillHideListener?: PluginListenerHandle;
  appStateChangeListener?: PluginListenerHandle;
  fontSizeDidChangeListener?: PluginListenerHandle;
  previousRoute!: PreviousRoute;
  queryParams = ['openBasket', 'restrictedFlow', 'src', 'open', 'from', 'rwg_token'];
  style = style;

  // Tracked properties
  @tracked openBasket?: string;
  @tracked restrictedFlow?: string;
  @tracked src?: string;
  @tracked open?: string;
  @tracked from?: string;
  @tracked rwg_token?: string;

  // Lifecycle methods
  constructor() {
    super(...arguments);
  }

  // Init
  init() {
    super.init();
    if (this.device.isHybrid) {
      App.addListener('backButton', async () => await this.handleBackButton()).then(
        listener => (this.backButtonListener = listener)
      );

      if (this.device.isCapacitorPluginAvailable('Keyboard')) {
        Keyboard.addListener('keyboardWillShow', async info => {
          if (this.device.isHybridIOS) {
            await StatusBar.hide();
          }

          if (info.identifier) {
            this.analytics.trackEvent(AnalyticsEvents.KeyboardShown, () => ({
              [AnalyticsProperties.KeyboardIdentifier]: info.identifier,
            }));
          }
        }).then(listener => (this.keyboardWillShowListener = listener));
      }

      if (this.device.isHybridIOS) {
        Keyboard.addListener('keyboardWillHide', async () => await StatusBar.show()).then(
          listener => (this.keyboardWillHideListener = listener)
        );
        Keyboard.addListener('fontSizeDidChange', () => this.setPreferredFontSize()).then(
          listener => (this.fontSizeDidChangeListener = listener)
        );

        this.setPreferredFontSize();
      }
      App.addListener('appStateChange', async state => await this.handleAppStateChange(state)).then(
        listener => (this.appStateChangeListener = listener)
      );
      App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
        if (event.url.includes('/resetpassword')) {
          await this.session.logout();
        }

        this.handleReferralToken();

        // Navigate directly to whatever the app URL was, for example, a vendor
        if (event.url.startsWith('https')) {
          const path = /https:\/\/[\w.\-_]*\/(.*)/.exec(event.url);

          if (path && path[1]) {
            if (path[1].startsWith('user/oauthcallback')) {
              try {
                const params = new URLSearchParams(path[1].split('?')[1]);
                const code = params.get('code');
                const state = params.get('state');

                if (!code || !state) {
                  return;
                }

                const oauthResult = await fetchOAuthCallback(
                  `${window.Olo.hybridAppHost}/user/oauthcallback?state=${state}&code=${code}&hybridCallbackComplete=true`,
                  false
                );

                this.session.handleCompletedOauthFlow(oauthResult, '/');
              } catch (e) {
                this.error.reportError(e);
              }
            } else {
              // viewing vendor menus
              this.window.location().assign('/' + path[1]);
            }
          }
        } else {
          let oauthCallbackParts: { state: string; code: string } | undefined = undefined;
          try {
            let url = event.url.split(':')[1];
            const facebookRedirectUriSecurityToken = '#_=_';
            if (url.endsWith(facebookRedirectUriSecurityToken)) {
              url = url.substring(0, url.length - facebookRedirectUriSecurityToken.length);
            }
            oauthCallbackParts = JSON.parse(atob(url));
          } catch (e) {
            this.error.reportError(e);
          }

          if (oauthCallbackParts?.state && oauthCallbackParts.code) {
            try {
              const oauthResult = await fetchOAuthCallback(
                `${window.Olo.hybridAppHost}/user/oauthcallback?state=${oauthCallbackParts.state}&code=${oauthCallbackParts.code}&hybridCallbackComplete=true`,
                false
              );

              this.session.handleCompletedOauthFlow(oauthResult, '/');
            } catch (e) {
              this.error.reportError(e);
            }
          }
        }
      });
    }
  }

  // WillDestroy
  willDestroy() {
    super.willDestroy();
    this.backButtonListener?.remove();
    this.keyboardWillShowListener?.remove();
    this.keyboardWillHideListener?.remove();
    this.appStateChangeListener?.remove();
    this.fontSizeDidChangeListener?.remove();
  }

  // Other methods
  handleCartState() {
    if (this.openBasket === 'true') {
      if (this.from === RouteChangeFrom.BASKET_CONTENTS) {
        this.session.isEditingOrder = true;
        this.session.editedOrderFromCheckout = true;
        this.from = undefined;
      }
      this.basket.open();
      this.openBasket = undefined;
    }
  }

  handleReferralToken() {
    if (this.rwg_token) {
      this.storage.rwg_token = this.rwg_token;
    }
  }

  handleOpenState() {
    switch (this.open) {
      case StaticContentType.CONTACT_US:
      case StaticContentType.OPT_OUT_GUIDE:
      case StaticContentType.PRIVACY_POLICY:
      case StaticContentType.USER_AGREEMENT:
        this.bus.trigger('showStaticContent', { type: this.open });
        this.open = undefined;
        break;
      default:
        // Clear the query param and then do nothing
        this.open = undefined;
        break;
    }
  }

  async handleBackButton(): Promise<void> {
    if (this.sideMenu.isActive) {
      this.sideMenu.close();
    } else if (this.basket.isOpen) {
      this.basket.close();
    } else if (this.router.currentRouteName === 'index') {
      return this.device.exitApp();
    } else {
      history.back();
    }

    return Promise.resolve();
  }

  async handleAppStateChange(state: AppState) {
    if (state.isActive) {
      await this.handleAppForegrounded();
    } else {
      await this.handleAppBackgrounded();
    }
  }

  async handleAppForegrounded() {
    await this.device.checkForNativeUpdate();
  }

  async handleAppBackgrounded() {
    await Preferences.set({ key: LAST_BACKGROUNDED_KEY, value: Date.now().toString() });
  }

  async setPreferredFontSize(): Promise<void> {
    if (Capacitor.isPluginAvailable('TextZoom')) {
      const { TextZoom } = await import('@capacitor/text-zoom');
      TextZoom.getPreferred().then(result => TextZoom.set({ value: result.value }));
    }
  }

  // Tasks

  // Actions and helpers

  @action
  logout() {
    return this.session.logout().then(redirectUrl => {
      this.storage.redirectFromHome = true;
      this.basket.clear();
      this.sideMenu.close();

      this.window.location().assign(redirectUrl);
    });
  }
}
