export default {
  "root": "_root_1yf3bj",
  "isSelected": "_isSelected_1yf3bj",
  "isDisabled": "_isDisabled_1yf3bj",
  "contentRow": "_contentRow_1yf3bj",
  "nameRow": "_nameRow_1yf3bj",
  "name": "_name_1yf3bj",
  "check": "_check_1yf3bj",
  "content": "_content_1yf3bj",
  "labels": "_labels_1yf3bj",
  "hasOneLabel": "_hasOneLabel_1yf3bj",
  "overrideLabel": "_overrideLabel_1yf3bj",
  "price": "_price_1yf3bj",
  "calories": "_calories_1yf3bj",
  "hasPrice": "_hasPrice_1yf3bj",
  "hasOverride": "_hasOverride_1yf3bj",
  "yieldedContent": "_yieldedContent_1yf3bj",
  "quantity": "_quantity_1yf3bj",
  "break": "_break_1yf3bj",
  "isDisabledDescription": "_isDisabledDescription_1yf3bj",
  "imageContainer": "_imageContainer_1yf3bj",
  "image": "_image_1yf3bj"
};
