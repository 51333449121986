import Store from 'ember-data/store';

import { getHandoffLabel } from 'mobile-web/lib/order-criteria';
import { Calendar } from 'mobile-web/lib/time/calendar';

import { HandoffMode } from './order-criteria';

export const filterVendorSchedule = (
  vendorScheduleCalendars: Calendar[],
  vendorSupportedHandoffModes: HandoffMode[],
  handoffModesHiddenFromSelection: HandoffMode[],
  store: Store
): Calendar[] => {
  if (!handoffModesHiddenFromSelection.length) {
    return vendorScheduleCalendars;
  }

  // Always include business hours.
  const calendars: Calendar[] =
    vendorScheduleCalendars.filter(cal => cal.scheduleDescription === 'Business') ?? [];

  vendorSupportedHandoffModes.forEach(hm => {
    if (!handoffModesHiddenFromSelection.includes(hm)) {
      const calendar = vendorScheduleCalendars.find(
        cal => cal.scheduleDescription === getHandoffLabel(hm, store)
      );
      if (calendar) {
        calendars.push(calendar);
      }
    }
  });

  return calendars;
};
