import DS from 'ember-data';

import { BILLING_DETAILS_ID } from 'mobile-web/models/billing-details';

export default class BillingDetailsSerializer extends DS.JSONSerializer.extend(
  DS.EmbeddedRecordsMixin
) {
  attrs = {
    billingSchemes: { embedded: 'always' },
    taxExemptAccounts: { serialize: 'records', deserialize: 'records' },
  };

  normalize(
    modelClass: DS.Model,
    resourceHash: {
      id: number;
      allowCardsOnFile?: boolean;
      allowSplitPayments?: boolean;
      canPayCash?: boolean;
      doCreditCardStreetAddressValidation?: boolean;
      showTip?: boolean;
    }
  ): UnknownObject {
    // Ember expects all models to have an ID, so we make one up.
    resourceHash.id = BILLING_DETAILS_ID;
    // We need every boolean property to be present and have a value;
    // if we don't, Ember Data treats the undefined property as not having been updated.
    resourceHash.allowCardsOnFile = resourceHash.allowCardsOnFile || false;
    resourceHash.allowSplitPayments = resourceHash.allowSplitPayments || false;
    resourceHash.canPayCash = resourceHash.canPayCash || false;
    resourceHash.doCreditCardStreetAddressValidation =
      resourceHash.doCreditCardStreetAddressValidation || false;
    resourceHash.showTip = resourceHash.showTip || false;
    return super.normalize(modelClass, resourceHash);
  }
}
