import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

import { HeadingLevel } from 'mobile-web/components/heading';
import TicketModel from 'mobile-web/models/ticket';
import Ticket from 'mobile-web/models/ticket';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import OnPremiseService from 'mobile-web/services/on-premise';

import style from './index.m.scss';

interface Args {
  model: { checks: Array<Ticket>; tableNumber: string };
}

interface Signature {
  Args: Args;
}

export enum CheckStatus {
  NoCheck = 'NoCheck',
  AllPaid = 'AllPaid',
  OpenChecks = 'OpenChecks',
}
export default class PaySelectRoute extends Component<Signature> {
  @service analytics!: AnalyticsService;
  @service router!: RouterService;
  @service onPremise!: OnPremiseService;

  style = style;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.analytics.trackEvent(AnalyticsEvents.PayAtTableSelectCheckPage, () => ({
      [AnalyticsProperties.NumberOfOpenChecks]: this.checks.filter(check => check.totals.due > 0)
        .length,
      [AnalyticsProperties.TableNumber]: this.tableId,
    }));
  }

  get checkStatus() {
    if (this.checks.length === 0) {
      return CheckStatus.NoCheck;
    }
    return this.checks.some(check => check.totals.due > 0)
      ? CheckStatus.OpenChecks
      : CheckStatus.AllPaid;
  }

  get multipleChecks(): boolean {
    return !this.onPremise.isSingleCheck(this.checks);
  }

  get checks(): Array<Ticket> {
    return this.args.model.checks;
  }

  get headingLevel(): HeadingLevel {
    return 1;
  }

  get itemLimit(): number {
    return 3;
  }

  get tableId() {
    return this.args.model.tableNumber;
  }

  // Tasks
  goToCheckoutTask = task(async (ticket: TicketModel): Promise<void> => {
    this.analytics.trackEvent(AnalyticsEvents.PayAtTableSelectCheckClick, () => ({
      [AnalyticsProperties.NumberOfOpenChecks]: this.checks.filter(
        checkObj => checkObj.totals.due > 0
      ).length,
      [AnalyticsProperties.CheckNumber]: ticket.ticketNumber,
      [AnalyticsProperties.TableNumber]: this.tableId,
    }));

    await this.onPremise.payAtTableCheckout(ticket);
  });

  // Actions and helpers
  @action
  async goToCheckout(ticket: TicketModel) {
    this.goToCheckoutTask.perform(ticket);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::PaySelectRoute': typeof PaySelectRoute;
  }
}
