import { service } from '@ember/service';

import ExternalLoginProviders from 'mobile-web/components/external-login-providers';
import { LoginProvider } from 'mobile-web/models/bootstrap-data';
import DeviceService from 'mobile-web/services/device';
import FeaturesService from 'mobile-web/services/features';
import OloAuthService from 'mobile-web/services/olo-auth';

import style from './index.m.scss';

export default class LoginProviders extends ExternalLoginProviders {
  // Service injections
  @service device!: DeviceService;
  @service features!: FeaturesService;
  @service oloAuth!: OloAuthService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get oloAuthLoginProviders(): LoginProvider[] | undefined {
    return this.device.isHybridIOS && this.features.flags['hide-olo-auth-on-ios']
      ? this.args.loginProviders.filter(p => !p.isOloAuth)
      : this.args.loginProviders;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OloAuth::LoginProviders': typeof LoginProviders;
  }
}
