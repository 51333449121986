export default {
  "container": "_container_1ph6k1",
  "deliveryFeeLabel": "_deliveryFeeLabel_1ph6k1",
  "customFeeLabel": "_customFeeLabel_1ph6k1",
  "donationTooltip": "_donationTooltip_1ph6k1",
  "lineItem": "_lineItem_1ph6k1",
  "discountLineItem": "_discountLineItem_1ph6k1 _lineItem_1ph6k1",
  "feeDesc": "_feeDesc_1ph6k1",
  "infoIcon": "_infoIcon_1ph6k1"
};
