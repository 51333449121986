import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import { hash } from 'rsvp';

import authenticated from 'mobile-web/decorators/authenticated';
import DeviceService from 'mobile-web/services/device';
import LoyaltyService from 'mobile-web/services/loyalty';
import OloAuthService from 'mobile-web/services/olo-auth';
import SessionService from 'mobile-web/services/session';
import WindowService from 'mobile-web/services/window';

@authenticated
export default class MyAccountRoute extends Route {
  @service loyalty!: LoyaltyService;
  @service session!: SessionService;
  @service oloAuth!: OloAuthService;
  @service window!: WindowService;
  @service device!: DeviceService;
  @service store!: DS.Store;

  async beforeModel(transition: Transition) {
    if (this.session.isOloAuthLogin) {
      transition.abort();

      if (
        this.device.isHybrid &&
        this.device.isCordovaPluginAvailable('cordova-plugin-inappbrowser')
      ) {
        this.device.openInAppBrowser(`${this.oloAuth.channelManageAccountUrl}&is-hybrid=true`);
      } else {
        this.window.location().assign(this.oloAuth.channelManageAccountUrl);
      }
    }
  }

  async model() {
    const user = this.session.currentUser!; // @authenticated guarantees this
    const memberships = this.loyalty.loadMemberships();
    const schemes = this.store.findAll('loyalty-scheme');
    const billingMemberships = await this.store.query('billing-membership', {});
    await Promise.all(billingMemberships.map(m => m.tryLoadBalance()));

    return hash({ user, memberships, billingMemberships, schemes });
  }
}
