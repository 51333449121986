import { assert } from '@ember/debug';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import MotionService from 'ember-animated/services/-ea-motion';
import fade from 'ember-animated/transitions/fade';
import IntlService from 'ember-intl/services/intl';

import { HeadingLevel } from 'mobile-web/components/heading';
import { parseMenuImageURLDimensions } from 'mobile-web/lib/image';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import { numberOr0 } from 'mobile-web/lib/utilities/numeric';
import BasketProductModel from 'mobile-web/models/basket-product';
import ProductModel from 'mobile-web/models/product';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import { ProductClickFrom, toRecommendationSource } from 'mobile-web/services/global-data';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import GroupOrderService from 'mobile-web/services/group-order';
import NotificationsService from 'mobile-web/services/notifications';
import OrderCriteriaService from 'mobile-web/services/order-criteria';

import style from './index.m.scss';

export const MAX_DESC_LENGTH = 70;

export enum ProductCardMode {
  Menu = 'menu',
  Modal = 'modal',
  Page = 'page',
  List = 'list',
}

export enum QuickAddState {
  Default = 'default',
  Active = 'active',
  Success = 'success',
}

interface Args {
  // Required arguments
  product: ProductModel;

  // Optional arguments
  headingLevel?: HeadingLevel;
  isLandscape?: boolean;
  mode?: EnumOrValue<ProductCardMode>;
  location?: ProductClickFrom;
  categoryIndex?: number;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class ProductCard extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service features!: FeaturesService;
  @service intl!: IntlService;
  @service('-ea-motion') motion!: MotionService;
  @service notifications!: NotificationsService;
  @service router!: RouterService;
  @service store!: DS.Store;
  @service globalEvents!: GlobalEventsService;
  @service groupOrder!: GroupOrderService;
  @service orderCriteria!: OrderCriteriaService;
  @service error!: ErrorService;

  // Untracked properties
  style = style;
  transition = fade;
  ids = guids(this, 'heading');

  // Tracked properties
  @tracked basketProduct?: BasketProductModel;
  @tracked showSuccess = false;

  // Getters and setters

  get quickAddState(): QuickAddState {
    if (this.showSuccess) {
      return QuickAddState.Success;
    }

    if (this.basketProduct && this.basketProduct.saveTask.isRunning) {
      return QuickAddState.Active;
    }

    return QuickAddState.Default;
  }

  get isQuickAddInProgress(): boolean {
    return this.quickAddState !== QuickAddState.Default;
  }

  get modeClass(): string {
    return this.style[this.mode];
  }

  get isProductPage(): boolean {
    return this.mode === ProductCardMode.Page || this.mode === ProductCardMode.Modal;
  }

  get showProductName(): boolean {
    return this.mode !== ProductCardMode.Modal;
  }

  get showFeatured(): boolean {
    return this.mode === ProductCardMode.Menu && this.args.product.isFeatured;
  }

  get showMostOrdered(): boolean {
    return (
      this.mode === ProductCardMode.Menu &&
      this.args.product.isMostOrdered &&
      this.args.location === ProductClickFrom.MostOrdered
    );
  }
  get mostOrderedPosition(): number {
    return (this.args.categoryIndex ?? 0) + 1;
  }

  get linkToModels(): string[] {
    return [this.args.product.vendor.slug, this.args.product.id];
  }

  /**
   * Indicates that the quick-add button is disabled and can't quick-add
   * additional items
   */
  get quickAddDisabled(): boolean {
    /**
     * basketMaxQuantity is a required field on admin, because it can be undefined on serve until we merge [OLO-33689]
     * we are defaulting to 8000
     */
    const basketMaxQuantity = this.channel.settings?.basketMaxQuantity ?? 8000;

    const productMaxQuantity = this.args.product.maximumTotalQuantity;

    const productQuantity = this.basket.basketProducts
      .filter(item => item.product.get('id') === this.args.product.id)
      .reduce((sum, product) => sum + product.quantity, 0);

    const basketProductQuantity = this.basket.basketProducts
      .filter(item => item.product.get('isSingleUse') === false)
      .reduce((sum, product) => sum + product.quantity, 0);

    if (this.args.product.isSingleUse) {
      return isSome(productMaxQuantity) ? productQuantity >= productMaxQuantity : false;
    }

    return (
      basketProductQuantity >= basketMaxQuantity ||
      (isSome(productMaxQuantity) ? productQuantity >= productMaxQuantity : false)
    );
  }

  /**
   * Indicates that the quick-add functionality isn't supported for this product
   */
  get quickAddSupported(): boolean {
    return this.mode === ProductCardMode.Menu && this.args.product.quickAddSupported;
  }

  get showAvailabilityDescription(): boolean {
    const showProductAvailability = this.channel.settings?.showProductAvailability ?? true;
    const hasDescription = Boolean(this.args.product.availabilityDescription);
    return this.mode !== ProductCardMode.List && showProductAvailability && hasDescription;
  }

  get productImage() {
    const src = this.args.product.menuImage;
    const singleUse = this.args.product.isSingleUse;
    if (!src || singleUse) {
      return undefined;
    }

    const { width, height } = parseMenuImageURLDimensions(src, this.error);
    return {
      src,
      alt: this.args.product.name,
      width,
      height,
    };
  }

  get showPrice(): boolean {
    return (
      this.args.product.baseCost > 0 &&
      (this.fullDescription || (this.channel.settings?.showProductMenuPrices ?? true))
    );
  }

  get showPriceAndCalorieLine(): boolean {
    return !(this.mode === ProductCardMode.List && this.isDisabled);
  }

  get isDisabled(): boolean {
    return this.args.product.isDisabled;
  }

  get showDescription(): boolean {
    return this.mode !== ProductCardMode.List && !isEmpty(this.description);
  }

  get showLabels(): boolean {
    return this.mode !== ProductCardMode.List;
  }

  get description(): string {
    const product = this.args.product;
    if (this.fullDescription) {
      return product.description;
    }
    if (isEmpty(product.shortDescription)) {
      return product.description.length > MAX_DESC_LENGTH
        ? `${product.description.slice(0, MAX_DESC_LENGTH)}...`
        : product.description;
    }
    return product.shortDescription;
  }

  get fullDescription() {
    return this.mode !== ProductCardMode.Menu;
  }

  get isLandscape() {
    return this.args.isLandscape ?? false;
  }

  get mode() {
    return this.args.mode ?? ProductCardMode.Menu;
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    assert('`product` is required', isSome(this.args.product));
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  quickAdd(e: Event) {
    e.stopPropagation();

    if (this.groupOrder.isLocked) {
      this.groupOrder.showGroupOrderLockedModal();
      return;
    }

    if (this.quickAddDisabled) {
      return;
    }

    const product = this.args.product;

    this.analytics.trackEvent(AnalyticsEvents.AddToCartIntent, () => ({
      [AnalyticsProperties.ProductName]: product.get('name'),
      [AnalyticsProperties.ProductID]: product.get('id'),
      [AnalyticsProperties.ProductCategory]: product.get('category')?.name,
      [AnalyticsProperties.ProductBasePrice]: product.get('baseCost'),
      [AnalyticsProperties.ProductAvailableOptionGroupCount]: numberOr0(
        product.optionGroups?.length
      ),
      [AnalyticsProperties.AddToCartMethod]: 'Quick Add',
      [AnalyticsProperties.AddToCartType]: 'Add',
      [AnalyticsProperties.HasVisibleCalories]:
        isSome(product.vendor.settings.showCalories) && isSome(product.get('calorieLabel')),
      [AnalyticsProperties.VisibleLabels]: product.get('labels')?.map(l => l.name),
      [AnalyticsProperties.HasProductImages]: !isEmpty(product.get('images')),
      [AnalyticsProperties.HasCategoryImages]: !isEmpty(product.get('category')?.images),
      [AnalyticsProperties.IsFeatured]: product.get('isFeatured'),
      [AnalyticsProperties.IsSingleUse]: product.get('isSingleUse'),
      [AnalyticsProperties.RecommendationSource]: toRecommendationSource(this.args.location),
      [AnalyticsProperties.IsRecentItemPersonalized]:
        this.args.location === ProductClickFrom.RecentItem &&
        product.get('recentItemCategory')?.name.includes("'s"),
      [AnalyticsProperties.QuickAddSupported]: product.get('quickAddSupported'),
      //When quick add is clicked, no modifiers are included but we still need to send this property for consistency
      [AnalyticsProperties.NumberOfTotalMods]: 0,
      [AnalyticsProperties.NumberOfRequiredMods]: 0,
      [AnalyticsProperties.NumberOfDefaultedMods]: 0,
      [AnalyticsProperties.NumberOfPricedMods]: [],
      [AnalyticsProperties.NumberOfImageMods]: 0,
      [AnalyticsProperties.SumOfModPrices]: 0,
    }));

    this.basket.quickAddTask.perform(product, this.args.location, () => {
      if (this.orderCriteria.isMissingDeliveryAddress()) {
        this.orderCriteria.openModal({ componentNameInitiatedModal: 'Product Card' });
      }
    });
  }

  @action
  goToProduct(e: Event) {
    const target = e.target as HTMLElement;

    if (!this.isProductPage) {
      if (this.mode !== ProductCardMode.List) {
        e.stopPropagation();
      }

      const product = this.args.product;

      if (this.isDisabled) {
        return;
      }

      this.globalEvents.trigger(
        GlobalEventName.ClickProductLink,
        {
          ...product.serializeForGlobalData(),
          hasImages: !!this.productImage,
          hasPrice: this.showPrice,
        },
        (this.args.location ?? 'vendor-menu') as ProductClickFrom
      );

      this.analytics.trackEvent(AnalyticsEvents.ViewProductCustomization, () => ({
        [AnalyticsProperties.ProductName]: product.name,
        [AnalyticsProperties.ProductCategory]: product.category?.name,
        [AnalyticsProperties.ProductBasePrice]: product.baseCost,
        [AnalyticsProperties.HasVisibleCalories]: !isEmpty(product.calorieLabel),
        [AnalyticsProperties.VisibleLabels]: product.labels.map(l => l.name),
        [AnalyticsProperties.HasProductImages]: !!this.productImage,
        [AnalyticsProperties.HasCategoryImages]: !isEmpty(product.category?.bannerImage),
        [AnalyticsProperties.HasVisiblePrice]: this.showPrice,
        [AnalyticsProperties.ProductAvailableOptionGroupCount]: product
          .hasMany('optionGroups')
          .ids().length,
        [AnalyticsProperties.Source]: 'Product Card',
      }));

      let requiredCount = 0;
      let defaultCount = 0;
      let imageCount = 0;
      let priceSum = 0;
      const priceOfModifier: number[] = [];
      let totalModifiers = 0;

      product.get('optionGroups')?.forEach(og => {
        if (og.isRequired) requiredCount++;

        og.choices.forEach(ch => {
          if (og.isRequired && ch.get('isDefault')) defaultCount++;
          if (ch.image?.filename) imageCount++;
          const priceDifference: number = ch.get('priceDifference') ?? 0;
          priceSum += priceDifference;
          if (priceDifference > 0) {
            priceOfModifier.push(priceDifference);
          }

          totalModifiers++;
        });
      });

      this.analytics.trackEvent(AnalyticsEvents.AddToCartIntent, () => ({
        [AnalyticsProperties.ProductName]: product.get('name'),
        [AnalyticsProperties.ProductID]: product.get('id'),
        [AnalyticsProperties.ProductCategory]: product.get('category')?.name,
        [AnalyticsProperties.ProductBasePrice]: product.get('baseCost'),
        [AnalyticsProperties.ProductAvailableOptionGroupCount]: numberOr0(
          product.optionGroups?.length
        ),
        [AnalyticsProperties.HasVisibleCalories]:
          isSome(product.vendor.settings.showCalories) && isSome(product.get('calorieLabel')),
        [AnalyticsProperties.VisibleLabels]: product.get('labels')?.map(l => l.name),
        [AnalyticsProperties.HasProductImages]: !isEmpty(product.get('images')),
        [AnalyticsProperties.HasCategoryImages]: !isEmpty(product.get('category')?.images),
        [AnalyticsProperties.IsFeatured]: product.get('isFeatured'),
        [AnalyticsProperties.IsSingleUse]: product.get('isSingleUse'),
        [AnalyticsProperties.RecommendationSource]: toRecommendationSource(this.args.location),
        [AnalyticsProperties.IsRecentItemPersonalized]:
          this.args.location === ProductClickFrom.RecentItem &&
          product.get('recentItemCategory')?.name.includes("'s"),
        [AnalyticsProperties.QuickAddSupported]: product.get('quickAddSupported'),
        [AnalyticsProperties.NumberOfTotalMods]: totalModifiers,
        [AnalyticsProperties.NumberOfRequiredMods]: requiredCount,
        [AnalyticsProperties.NumberOfDefaultedMods]: defaultCount,
        [AnalyticsProperties.NumberOfPricedMods]: priceOfModifier,
        [AnalyticsProperties.NumberOfImageMods]: imageCount,
        [AnalyticsProperties.SumOfModPrices]: priceSum,
      }));

      if (product.quickAddSupported && this.router.currentRouteName !== 'menu.vendor.index') {
        this.bus.trigger('showProductModal', {
          product,
          onSave: () => {
            this.basket.open();
          },
        });
      } else if (product.quickAddSupported && this.device.isHybrid) {
        this.bus.trigger('showProductModal', {
          product,
        });
      } else if (this.mode !== ProductCardMode.List && target.tagName.toLowerCase() !== 'a') {
        this.router.transitionTo('menu.vendor.products', product.vendor.slug, product.id, {
          queryParams: { clickFrom: this.args.location },
        });
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProductCard: typeof ProductCard;
  }
}
