import { Calendar, Weekday } from 'mobile-web/lib/time/calendar';
import { Tag } from 'mobile-web/models/vendor-search-result';

export type WeeklySchedule = {
  calendars: Calendar[];
  currentWeekDay: Weekday;
};

export enum ScheduleAvailabilityKeywords {
  Closed = 'Closed',
  AllDay = 'All Day',
  Midnight = 'Midnight',
}

export function toTags(
  weeklySchedule: WeeklySchedule,
  day = weeklySchedule.currentWeekDay,
  calendars?: Calendar[]
): Tag[] {
  if (!calendars) {
    calendars = weeklySchedule.calendars;
  }
  return calendars.reduce((tags, { scheduleDescription: description, schedule }) => {
    const type = 'neutral';
    const daySchedule = schedule.find(s => s.weekDay === day);
    if (daySchedule) {
      const content = `${description} Hours: ${daySchedule.description}`;
      tags.push({ type, content });
    }
    return tags;
  }, [] as Tag[]);
}

export function hoursForDay(day: Weekday, calendar?: Calendar): string {
  return (
    calendar?.schedule.find(s => s.weekDay === day)?.description ||
    ScheduleAvailabilityKeywords.Closed
  );
}

export const WeeklySchedule = {
  toTags,
};

// Declaration-merge the type and the object.
export default WeeklySchedule;
