import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { PASSWORD_MINLENGTH } from 'mobile-web/lib/security';
import { Autocomplete } from 'mobile-web/lib/utilities/html-types';
import { ValidationMessage } from 'mobile-web/lib/validation';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onChange: (value: string) => void;
  value: string;

  // Optional arguments
  autocomplete?: Autocomplete;
  class?: string;
  label?: string;
  name?: string;
  required?: boolean;
  requireMinlength?: boolean;
  validationMessages?: ValidationMessage[];
  focusCategory?: string;
  isDirty?: boolean;
  useValidationRefactor?: boolean;
  validate?: () => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class Password extends Component<Signature> {
  @tracked visible = false;

  style = style;

  get icon(): string {
    return this.visible ? 'eye-off' : 'eye';
  }

  get iconLabel(): string {
    return this.visible ? 'Hide password' : 'Show password';
  }

  get type(): 'password' | 'text' {
    return this.visible ? 'text' : 'password';
  }

  get value(): string {
    return this.args.value;
  }

  set value(newVal) {
    this.args.onChange(newVal);
  }

  get class(): string {
    return this.args.class ?? '';
  }

  get label(): string {
    return this.args.label ?? 'Password';
  }

  get minlength(): number | undefined {
    return this.args.requireMinlength ? PASSWORD_MINLENGTH : undefined;
  }

  get minlengthReached(): boolean {
    return this.minlength ? this.value.length >= this.minlength : true;
  }

  get name(): string {
    return this.args.name ?? 'password';
  }

  get isRequired(): boolean {
    return this.args.required ? true : false;
  }

  @action
  handleChange(value: string) {
    this.args.onChange(value);
  }

  @action
  toggleVisibility() {
    this.visible = !this.visible;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Password: typeof Password;
  }
}
