import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import collectionAction, { CollectionAction } from 'mobile-web/decorators/collection-action';
import { Address } from 'mobile-web/lib/location/address';
import { Binding, ValidationMessage } from 'mobile-web/lib/validation';

export type DeliveryAddressData = Address & {
  basketId?: EmberDataId;
};

export default class SavedAddressModel extends DS.Model {
  @service intl!: IntlService;
  @service store!: DS.Store;

  @tracked instructionsMessages: ValidationMessage[] = [];

  @DS.attr('string')
  streetAddress!: string;
  @DS.attr('string')
  building?: string;
  @DS.attr('string')
  city!: string;
  @DS.attr('string')
  zipCode!: string;
  @DS.attr('number')
  latitude?: number;
  @DS.attr('number')
  longitude?: number;
  @DS.attr('boolean')
  isDefault?: boolean;
  @DS.attr('string')
  instructions?: string;
  @DS.attr('boolean')
  contactlessDelivery?: boolean;

  @collectionAction<DeliveryAddressData, SavedAddressModel>({
    type: 'post',
    before: addressData => ({
      ...addressData,
      ignoreRequireInstructions: true,
    }),
    after: pushAddressPayload,
  })
  addDeliveryAddress!: CollectionAction<DeliveryAddressData, SavedAddressModel>;

  /**
   * This looks redundant, but it's actually needed to avoid the deprecation
   * ember-data:model.toJSON.
   * The issue was decorating order criteria with `@session`.
   * Delivery criteria have this model associated with them.
   * When `@session` tries to store a JSON representation of this model,
   * it calls `JSON.stringify`, which implicitly calls `toJSON`.
   * If you don't override `toJSON`, the base class `toJSON` will be called,
   * which throws the deprecation.
   */
  toJSON(): UnknownObject {
    return {
      id: this.id,
      streetAddress: this.streetAddress,
      building: this.building,
      city: this.city,
      zipCode: this.zipCode,
      latitude: this.latitude,
      longitude: this.longitude,
      isDefault: this.isDefault,
      instructions: this.instructions,
      contactlessDelivery: this.contactlessDelivery,
    };
  }

  instructionsBinding(thisPath: string): Binding {
    return {
      targetProp: `${thisPath}.instructions`,
      validationMessagesProp: `${thisPath}.instructionsMessages`,
      ruleName: 'notBlank',
      message: this.intl.t('mwc.checkout.errors.requiredDeliveryInstructions'),
    };
  }
}

export function pushAddressPayload(
  this: DS.Model & { store: DS.Store },
  response: AnyObject
): SavedAddressModel {
  this.store.pushPayload({
    addresses: [response],
  });
  return this.store.peekRecord('address', response.id)!;
}
